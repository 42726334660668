import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, notification, Row, Space, Typography } from 'antd'
import { OrderStatuses } from '../../../../../../configs/enums/orderStatuses'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { transformDate } from '../../../../../../helpers/transformDate'
import { IResponsibleUser } from '../../../../../../models/IUser'
import { getInitialsFromName } from '../../../../../../helpers/getInitialsFromName'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import { IOrderStatus } from '../../../../../../models/IOrder'
import { getOrderToEdit } from '../../../../../../store/reducers/order/order.slice'
import { useCreateDocumentMutation } from '../../../../../../services/document.api'
import GenerateDocument from '../../../../../../components/GenerateDocument/GenerateDocument'
import { useAppSelector } from '../../../../../../hooks/redux'
import { CloseOutlined } from '@ant-design/icons'
import { ColorForStatus } from '../../../../../../configs/enums/colorForStatus'
import { LabeledValue } from 'antd/es/select'
import s from './OrderHeader.module.scss'

const {Text} = Typography

interface IProps {
  ref?: React.RefObject<any>
  name: string
  orderStatus?: string
  createDate?: string
  responsibleUser?: IResponsibleUser
  isEditable: boolean
  orderStatuses: IOrderStatus[] | undefined
  orderIRI: string
  closeForm: () => void
}

// статус удаленного заказа
const deleted = '/api/v1/product-order-statuses/8'


const OrderHeader: FC<IProps> = (props) => {
  const {
    name,
    orderStatus = 'new',
    responsibleUser = {} as IResponsibleUser,
    createDate,
    isEditable,
    orderStatuses,
    orderIRI,
    closeForm
  } = props
  const order = useAppSelector(getOrderToEdit)
  const [confirmDeletedStatus, setConfirmDeletedStatus] = useState(false)
  const [open, setOpen] = useState(false)
  const [api, contextHolder] = notification.useNotification()
  const form = Form.useFormInstance()
  const [changeOrder, {data: dataNewOrder, isSuccess, isError}] = useChangeOrderMutation()
  const [createDoc, {
    isLoading: isLoadingGenerateDoc,
    isSuccess: isSuccessGenerateDoc,
    isError: isErrorGenerateDoc,
    error: dataDocumentError
  }] = useCreateDocumentMutation()

  // уведомление создания заказа ->
  useEffect(() => {
    if (isSuccess) {
      setConfirmDeletedStatus(false)
      api.success({
        message: 'Статус заказа успешно изменен',
        duration: 3
      })

      if (dataNewOrder?.productOrderStatus.value === 'deleted') {
        closeForm()
      }
    }
  }, [isSuccess])
  useEffect(() => {
    if (isError) {
      api.error({
        message: 'Ошибка при изменении заказа',
        duration: 3
      })
      form.setFieldValue('status', orderStatus)
    }
  }, [isError])
  // <-

  // уведомление формирования документа ->
  useEffect(() => {
    if (isSuccessGenerateDoc) {
      api.success({
        message: 'Документ успешно сформирован',
        duration: 3
      })
      setOpen(false)
    }
  }, [isSuccessGenerateDoc])
  useEffect(() => {
    if (isErrorGenerateDoc && dataDocumentError && 'data' in dataDocumentError) {
      // @ts-ignore
      const errorMsg = dataDocumentError?.data?.detail
      api.error({
        message: errorMsg || 'Ошибка при формировании документа',
        duration: 3
      })
    }
  }, [isErrorGenerateDoc])
  // <-

  useEffect(() => {
    if (isEditable) {
      form.setFieldValue('status', order?.productOrderStatus?.productStatusIRI)
    }
  }, [order])

  const date = transformDate(createDate, true)
  const userName = getInitialsFromName(
    responsibleUser?.firstName || '',
    responsibleUser?.lastName || '',
    responsibleUser?.middleName || '')

  const openGenerateDocument = () => setOpen(true)
  const handleCancel = () => setOpen(false)

  const generateDocument = (company: string, document: string) => {
    if (company && document && orderIRI) {
      createDoc({
        firm_name: company,
        collection: document,
        product_order: orderIRI
      })
    }
  }

  // изменение статуса заказа ->
  const onSelect = (value: string | number | LabeledValue, option: any) => {
    if (value === deleted) {
      setConfirmDeletedStatus(true)
    }

    if (isEditable && order && value !== deleted) {
      changeOrder({
        id: order?.id,
        data: {
          'product_order_status': value
        }
      })
    }
  }

  const onConfirmDelete = () => {
    changeOrder({
      id: order?.id,
      data: {
        'product_order_status': deleted
      }
    })
  }

  const onCancelDeleted = () => {
    form.setFieldValue('status', orderStatus)
    setConfirmDeletedStatus(false)
  }
  // <-

  if (!orderStatuses) return null

  return (
    <>
      <div className={s.orderHeader}>
        {contextHolder}

        <Button className={s.btnClose}
                onClick={() => closeForm()}
                icon={<CloseOutlined/>}
        ></Button>

        <Row>
          <Col span={12}>
            <Space direction="vertical">
              {
                isEditable && <Text className={s.orderHeaderInfo}>{date} - {userName}</Text>
              }

              <Row gutter={[0, 0]}>
                <Text className={s.orderName}>{name || ''}</Text>

                <Form.Item name={['status']} initialValue={orderStatuses[0]?.orderStatusIRI}>
                  <SelectCustom
                    widthOptions={'auto'}
                    heightOptions={22}
                    dropdownMatchSelectWidth={false}
                    bordered={false}
                    disabled={!isEditable}
                    options={
                      orderStatuses ? orderStatuses?.map((status) => ({
                        key: status.orderStatusIRI,
                        value: status.orderStatusIRI,
                        label: <span
                          style={{color: ColorForStatus[status.value]}}>- {status.cyrillicValue}</span>
                      })) : []
                    }
                    onSelect={onSelect}
                  />
                </Form.Item>
              </Row>
            </Space>
          </Col>
          {
            isEditable &&
            <Col span={6} offset={5}>
              <Button type={'primary'}
                      onClick={openGenerateDocument}
                      className={s.btnGenerateDoc}
              >Сформировать документ</Button>
            </Col>
          }
        </Row>

        <GenerateDocument open={open}
                          generateDocument={generateDocument}
                          handleCancel={handleCancel}
                          isLoadingGenerateDoc={isLoadingGenerateDoc}
        />
      </div>

      <Modal open={confirmDeletedStatus}
             onCancel={onCancelDeleted}
             onOk={onConfirmDelete}
             cancelText={'Отменить'}
             okText={'Сохранить'}
             title={<h4>Подтвердить удаление заказа <Text type={'warning'}>{name}</Text></h4>}
             closable={false}
      />
    </>
  )
}

export default OrderHeader