import { IOrder, IOrderStatus, IOrderTable } from '../models/IOrder'
import { apiSlice } from './api'
import { baseUrl } from '../configs/api/api.config'
import { OrderStatuses } from '../configs/enums/orderStatuses'

interface IResponseOrderStatus {
  '@id': string
  ordering: number
  value: keyof typeof OrderStatuses
  cyrillic_value: string
}

export const orderApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrder: build.query<IOrder, number>({
      query: (id) => `/product-orders/${id}`,
      transformResponse: (response: any): IOrder => {
        return ({
          orderIRI: response?.['@id'],
          id: response.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          type: response.type,
          productOrderStatus: {
            productStatusIRI: response.product_order_status['@id'],
            value: response.product_order_status.value,
            ordering: response.product_order_status.ordering
          },
          documents: [
            ...response?.documents?.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              firmName: item.name,
              link: `${baseUrl}${item.link}`,
              type: item.type,
              code: item.code,
              collection: item.collection
            }))
          ],
          paymentType: {
            paymentTypeIRI: response.payment_type['@id'],
            id: response.payment_type.id,
            name: response.payment_type.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepayment_summ,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response.responsible_user['@id'],
            firstName: response.responsible_user.first_name,
            lastName: response.responsible_user.last_name,
            middleName: response.responsible_user.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response['@id'] || '',
            id: response.contact.id,
            type: response.contact.type,
            firstName: response.contact.first_name,
            lastName: response.contact.second_name,
            middleName: response.contact.middle_name,
            fullName: response.contact.full_name,
            phone: response.contact.phone,
            email: response.contact.email,
            additionalContacts: response.contact.additional_contacts,
            address: response.contact.address,
            juriName: response.contact.juri_name,
            inn: response.contact.inn,
            kpp: response.contact.kpp,
            ogrn: response.contact.ogrn,
            bik: response.contact.bik,
            bankName: response.contact.bank_name,
            corrAccount: response.contact.corr_account,
            payAccount: response.contact.pay_account,
            yuriAddress: response.contact.yuri_address,
            managerFullname: response.contact.manager_fullname,
            createdAt: response.contact.created_at,
            updatedAt: response.contact.updated_at
          },
          price: response.price,
          productOrderItems: response.product_order_items.map((productOrderItem: any) => ({
            productOrderItemIRI: productOrderItem?.['@id'],
            id: productOrderItem.id,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                productIRI: productOrderItem.product?.['@id'],
                id: productOrderItem.product.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                id: productOrderItem.materials[0].id,
                createdAt: productOrderItem.materials[0].created_at,
                updatedAt: productOrderItem.materials[0].updated_at,
                material: productOrderItem.materials[0].material,
                name: productOrderItem.materials[0].name,
                provider: productOrderItem.materials[0].provider,
                price: productOrderItem.materials[0].price
              },
              {
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            productOrderItemOptions: productOrderItem.product_order_item_options.map((option: any) => ({
              id: option.id,
              optionIRI: option?.['@id'],
              createdAt: option.created_at,
              updatedAt: option.updated_at,
              product: {
                productIRI: option?.product?.['@id'] || null,
                id: option.product?.id || null,
                name: option.product?.name || '',
                code: option.product?.code || '',
                type: option.product?.type || ''
              },
              productName: option.product_name,
              productSupport: option.product_support,
              price: option.price,
              quantity: option.quantity,
              cost: option.cost,
              discount: option.discount,
              sum: option.summ,
              productSize: option.product_size || null,
              productSizeValue: option.product_size_value,
              hasBackwall: option.has_backwall,
              note: option.note,
            }))
          })),
        })
      },
      providesTags: result => ['Order'],
    }),
    getOrders: build.query<IOrderTable[], {
      company?: string, code?: string, status?: string, dateFrom?: string, dateTo?: string, perPage?: number, page?: number
    }>({
      query: ({company, code, status, dateFrom, dateTo, perPage = 1000, page = 1}) => {
        const params: any = {
          'documents.firm_name': company,
          code,
          product_order_status: status,
          per_page: perPage,
          page
        }
        if (dateFrom) {
          params['created_at[after]'] = `${dateFrom} 00:00:00`
        }
        if (dateTo) {
          params['created_at[before]'] = `${dateTo} 23:59:59`
        }

        return ({
          url: '/product-orders',
          params
        })
      },
      transformResponse: (response: any) => {
        return [
          ...response['hydra:member'].map((item: any) => ({
            orderIRI: item['@id'],
            code: item.code,
            createdAt: item.created_at,
            deliveryDateFrom: item.delivery_date_from,
            deliveryDateTo: item.delivery_date_to,
            id: item.id,
            key: item.key,
            price: item.price,
            type: item.type,
            contact: {
              contactIRI: item.contact['@id'],
              firstName: item.contact.first_name,
              fullName: item.contact.full_name,
              id: item.contact.id,
              juriName: item.contact.juri_name,
              middleName: item.contact.middle_name,
              secondName: item.contact.second_name,
              type: item.contact.type
            },
            productOrderStatus: {
              '@id': item.product_order_status['@id'],
              ordering: item.product_order_status.ordering,
              value: item.product_order_status.value
            }
          }))
        ]
      },
      // keepUnusedDataFor: 5
      providesTags: result => ['Order'],
      // Only have one cache entry because the arg always maps to one string
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName
      // },
      // // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems) => {
      //   console.log(currentCache)
      //   console.log(newItems)
      //   currentCache.push(...newItems)
      // },
      // // Refetch when the page arg changes
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg
      // },
    }),
    createOrder: build.mutation<IOrder, any>({
      query: (data) => ({
        url: '/product-orders',
        method: 'POST',
        body: data
      }),
      transformResponse: (response: any): IOrder => {
        return {
          orderIRI: response?.['@id'],
          id: response.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          type: response.type,
          productOrderStatus: {
            productStatusIRI: response.product_order_status['@id'],
            value: response.product_order_status.value,
            ordering: response.product_order_status.ordering
          },
          documents: [
            ...response?.documents?.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              firmName: item.name,
              link: `${baseUrl}${item.link}`,
              type: item.type,
              code: item.code,
              collection: item.collection
            }))
          ],
          price: response.price,
          paymentType: {
            paymentTypeIRI: response.payment_type['@id'],
            id: response.payment_type.id,
            name: response.payment_type.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepaymentSum,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response.responsible_user['@id'],
            firstName: response.responsible_user.first_name,
            lastName: response.responsible_user.lastName,
            middleName: response.responsible_user.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response.contact?.['@id'] || '',
            id: response.contact.id,
            type: response.contact.type,
            firstName: response.contact.first_name,
            lastName: response.contact.second_name,
            middleName: response.contact.middle_name,
            fullName: response.contact.full_name,
            phone: response.contact.phone,
            email: response.contact.email,
            additionalContacts: response.contact.additional_contacts,
            address: response.contact.address,
            juriName: response.contact.juri_name,
            inn: response.contact.inn,
            kpp: response.contact.kpp,
            ogrn: response.contact.ogrn,
            bik: response.contact.bik,
            bankName: response.contact.bank_name,
            corrAccount: response.contact.corr_account,
            payAccount: response.contact.pay_account,
            yuriAddress: response.contact.yuri_address,
            managerFullname: response.contact.manager_fullname,
            createdAt: response.contact.created_at,
            updatedAt: response.contact.updated_at
          },
          productOrderItems: response.product_order_items.map((productOrderItem: any) => ({
            id: productOrderItem?.id,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                '@id': productOrderItem.product?.['@id'],
                id: productOrderItem?.product?.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                id: productOrderItem.materials[0].id,
                createdAt: productOrderItem.materials[0].created_at,
                updatedAt: productOrderItem.materials[0].updated_at,
                material: productOrderItem.materials[0].material,
                name: productOrderItem.materials[0].name,
                provider: productOrderItem.materials[0].provider,
                price: productOrderItem.materials[0].price
              },
              {
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            product_order_item_options: []
          })),
        }
      },
      invalidatesTags: ['Order']
    }),
    changeOrder: build.mutation<IOrder, { id: number, data: any }>({
      query: (body) => ({
        url: `/product-orders/${body.id}`,
        method: 'PUT',
        body: body.data
      }),
      transformResponse: (response: any): IOrder => {
        const transformOption = (options: any) => {
          let result = []
          for (const option in options) {
            result.push({
              optionIRI: options[option]['@id'],
              id: options[option].id,
              createdAt: options[option].created_at,
              updatedAt: options[option].updated_at,
              productName: options[option].product_name,
              quantity: options[option].quantity,
              price: options[option].price,
              cost: options[option].cost,
              discount: options[option].discount,
              summ: options[option].summ,
              product: {
                id: options[option].product?.id,
                name: options[option].product?.name,
                code: options[option].product?.code,
                type: options[option].product?.type
              },
            })
          }

          return result
        }

        return {
          orderIRI: response['@id'],
          id: response.id,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          code: response.code,
          codePrefix: response.code_prefix,
          codeNumber: response.code_number,
          type: response.type,
          productOrderStatus: {
            productStatusIRI: response.product_order_status['@id'],
            value: response.product_order_status.value,
            ordering: response.product_order_status.ordering
          },
          documents: [
            ...response?.documents?.map((item: any) => ({
              id: item.id,
              createdAt: item.created_at,
              firmName: item.name,
              link: `${baseUrl}${item.link}`,
              type: item.type,
              code: item.code,
              collection: item.collection
            }))
          ],
          price: response.price,
          paymentType: {
            paymentTypeIRI: response.payment_type['@id'],
            id: response.payment_type.id,
            name: response.payment_type.name,
            cyrillicValue: response?.payment_type?.cyrillic_value
          },
          prepaymentPercent: response.prepayment_percent,
          prepaymentSum: response.prepaymentSum,
          afterPaymentSum: response.afterpayment_summ || '',
          productionDateFrom: response.production_date_from,
          productionDateTo: response.production_date_to,
          deliveryDateFrom: response.delivery_date_from,
          deliveryDateTo: response.delivery_date_to,
          note: response.note,
          responsibleUser: {
            responsibleUserIRI: response.responsible_user['@id'],
            firstName: response.responsible_user.first_name,
            lastName: response.responsible_user.lastName,
            middleName: response.responsible_user.middle_name
          },
          workpoint: response.workpoint,
          contact: {
            hydraContactID: response['@id'] || '',
            id: response.contact.id,
            type: response.contact.type,
            firstName: response.contact.first_name,
            lastName: response.contact.second_name,
            middleName: response.contact.middle_name,
            fullName: response.contact.full_name,
            phone: response.contact.phone,
            email: response.contact.email,
            additionalContacts: response.contact.additional_contacts,
            address: response.contact.address,
            juriName: response.contact.juri_name,
            inn: response.contact.inn,
            kpp: response.contact.kpp,
            ogrn: response.contact.ogrn,
            bik: response.contact.bik,
            bankName: response.contact.bank_name,
            corrAccount: response.contact.corr_account,
            payAccount: response.contact.pay_account,
            yuriAddress: response.contact.yuri_address,
            managerFullname: response.contact.manager_fullname,
            createdAt: response.contact.created_at,
            updatedAt: response.contact.updated_at
          },
          productOrderItems: response.product_order_items?.map((productOrderItem: any) => ({
            id: productOrderItem.id,
            createdAt: productOrderItem.created_at,
            updatedAt: productOrderItem.updated_at,
            product: productOrderItem.product
              ? {
                // '@id': productOrderItem.product['@id'],
                id: productOrderItem.product.id,
                name: productOrderItem.product.name,
                code: productOrderItem.product.code,
                type: productOrderItem.product.type
              }
              : null,
            productName: productOrderItem.product_name,
            productSupport: productOrderItem.product_support,
            price: productOrderItem.price,
            quantity: productOrderItem.quantity,
            cost: productOrderItem.cost,
            discount: productOrderItem.discount,
            sum: productOrderItem.summ,
            productSize: productOrderItem.product_size || null,
            productSizeValue: productOrderItem.product_size_value,
            materials: [
              {
                id: productOrderItem.materials[0].id,
                createdAt: productOrderItem.materials[0].created_at,
                updatedAt: productOrderItem.materials[0].updated_at,
                material: productOrderItem.materials[0].material,
                name: productOrderItem.materials[0].name,
                provider: productOrderItem.materials[0].provider,
                price: productOrderItem.materials[0].price
              },
              {
                id: productOrderItem?.materials[1]?.id || '',
                createdAt: productOrderItem?.materials[1]?.created_at || '',
                updatedAt: productOrderItem?.materials[1]?.updated_at || '',
                material: productOrderItem?.materials[1]?.material || '',
                name: productOrderItem?.materials[1]?.name || '',
                provider: productOrderItem?.materials[1]?.provider || '',
                price: productOrderItem?.materials[1]?.price || ''
              } || {}
            ],
            hasBackwall: productOrderItem.has_backwall,
            note: productOrderItem.note,
            productOrderItemOptions: productOrderItem.product_order_item_options
              ? transformOption(productOrderItem.product_order_item_options)
              : null
          })),
        }
      },
      invalidatesTags: ['Order', 'Materials']
    }),
    deleteProductItems: build.mutation<void, number>({
      query: (id) => ({
        url: `/product-order-items/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order']
    }),
    deleteProductOrderItemOption: build.mutation<void, number>({
      query: (id) => ({
        url: `/product-order-item-options/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order']
    }),
    getOrderName: build.mutation<{ counter: number }, { prefix: string }>({
      query: (data) => ({
        url: '/order-counters/get-next',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Order']
    }),
    getProductOrderStatuses: build.query<IOrderStatus[], void>({
      query: () => '/product-order-statuses',
      transformResponse: (response: any): IOrderStatus[] => response['hydra:member'].map((status: IResponseOrderStatus) => ({
        orderStatusIRI: status['@id'],
        ordering: status.ordering,
        value: status.value,
        cyrillicValue: status.cyrillic_value
      }))
    }),
  }),
})

export const {
  useLazyGetOrderQuery,
  useLazyGetOrdersQuery,
  useGetOrderNameMutation,
  useCreateOrderMutation,
  useGetProductOrderStatusesQuery,
  useChangeOrderMutation,
  useDeleteProductItemsMutation,
  useDeleteProductOrderItemOptionMutation
} = orderApi

