import { MailFilled } from '@ant-design/icons'
import { AutoComplete, Col, Form, Input, Row, Typography } from 'antd'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import s from './LegalPerson.module.scss'
import { IOrder } from '../../../../../../models/IOrder'
import { IContact } from '../../../../../../models/IContact'

const {Text} = Typography

interface IProps {
  dataContact: IContact[]
  onBlurField: (e: React.FocusEvent<HTMLInputElement>, fieldName: string) => void
  onSearchContact: (e: ChangeEvent<HTMLInputElement>) => void
  changeContactDataForOrder: (contactIRI: string) => void
}

const LegalPerson: FC<IProps> = ({
                                   dataContact,
                                   onBlurField,
                                   onSearchContact,
                                   changeContactDataForOrder
                                 }) => {
  const [contact, setContact] = useState({} as IContact)
  const form = Form.useFormInstance()

  const formData = Form.useWatch([], form)
  useEffect(() => {
    console.log(formData)
  }, [formData])

  useEffect(() => {
    console.log(contact)
    if (contact.phone) {
      form.setFieldValue(['contactIRI'], contact.hydraContactID)
      form.setFieldValue(['phone'], contact.phone)
      form.setFieldValue(['email'], contact.email)
      form.setFieldValue(['contacts'], contact.additionalContacts)
      form.setFieldValue(['addressDelivery'], contact.address)
      form.setFieldValue(['fullName'], contact.fullName)
      form.setFieldValue(['anotherContact'], contact.additionalContacts)
      form.setFieldValue(['juriName'], contact.juriName)
      form.setFieldValue(['inn'], contact.inn)
      form.setFieldValue(['kpp'], contact.kpp)
      form.setFieldValue(['ogrn'], contact.ogrn)
      form.setFieldValue(['bik'], contact.bik)
      form.setFieldValue(['bank'], contact.bankName)
      form.setFieldValue(['corrAccount'], contact.corrAccount)
      form.setFieldValue(['giroAccount'], contact.payAccount)
      form.setFieldValue(['juriAddress'], contact.yuriAddress)
      form.setFieldValue(['deliveryAddress'], contact.address)
      form.setFieldValue(['fullNameDirector'], contact.managerFullname)
    }
  }, [contact.phone])

  const onSelectContact = (value: any, option: any) => {
    console.log(option)
    setContact(option)
    changeContactDataForOrder(option.hydraContactID)
  }

  return (
    <div className={s.legalPerson}>
      <Row className={s.marginBottom} gutter={[0, 12]}>
        <Col span={24}>
          <Text className={s.fieldLabel}>
            ФИО контактного лица<Text type={'danger'} className={s.required}>*</Text>
          </Text>
          <Form.Item name={'fullName'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'full_name')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={7}>
          <Text className={s.fieldLabel}>
            Телефон<Text type={'danger'} className={s.required}>*</Text>
          </Text>
          <Form.Item name={'phone'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <AutoComplete
              className={s.field}
              placeholder={'+_ (___) ___ __ __'}
              options={dataContact && dataContact.map((item) => ({
                ...item,
                key: item.id,
                value: item.phone,
                label: `${item.firstName || item.fullName}: ${item.phone}`
              }))}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlurField(e, 'phone')}
              onChange={(e) => onSearchContact(e)}
              children={<Input style={{height: '44px'}}/>}
              onSelect={onSelectContact}
              backfill={true}
            />
          </Form.Item>
        </Col>

        <Col span={7}>
          <Text className={s.fieldLabel}>Почта</Text>
          <Form.Item name={'email'}>
            <Input type={'text'}
                   placeholder={'Введите почту'}
                   className={s.field}
                   prefix={<MailFilled className={s.icon}/>}
                   onBlur={(e) => onBlurField(e, 'email')}
            />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Text className={s.fieldLabel}>Доп. контакты</Text>
          <Form.Item name={'additionalContact'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'additional_contacts')}/>
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={9}>
          <Text className={s.fieldLabel}>
            Юр. название
          </Text>
          <Form.Item name={'juriName'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'juri_name')}
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>ИНН</Text>
          <Form.Item name={'inn'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={10}
                   onBlur={(e) => onBlurField(e, 'inn')}
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>КПП</Text>
          <Form.Item name={'kpp'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={9}
                   onBlur={(e) => onBlurField(e, 'kpp')}
            />
          </Form.Item>
        </Col>

        <Col span={5}>
          <Text className={s.fieldLabel}>ОГРН/ОГРНИП</Text>
          <Form.Item name={'ogrn'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={13}
                   onBlur={(e) => onBlurField(e, 'ogrn')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col flex="155px">
          <Text className={s.fieldLabel}>БИК</Text>
          <Form.Item name={'bik'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={9}
                   onBlur={(e) => onBlurField(e, 'bik')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Банк</Text>
          <Form.Item name={'bank'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={50}
                   onBlur={(e) => onBlurField(e, 'bank_name')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Корреспондентский счёт</Text>
          <Form.Item name={'corrAccount'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={20}
                   onBlur={(e) => onBlurField(e, 'corr_account')}
            />
          </Form.Item>
        </Col>

        <Col flex="auto">
          <Text className={s.fieldLabel}>Расчётный счёт</Text>
          <Form.Item name={'giroAccount'}>
            <Input type={'text'}
                   className={s.field}
                   maxLength={20}
                   onBlur={(e) => onBlurField(e, 'pay_account')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={12}>
          <Text className={s.fieldLabel}>Юр. адрес</Text>
          <Form.Item name={'juriAddress'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'yuri_address')}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Text className={s.fieldLabel}>Адрес доставки</Text>
          <Form.Item name={'deliveryAddress'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'address')}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className={s.marginBottom} gutter={[12, 12]}>
        <Col span={24}>
          <Text className={s.fieldLabel}>ФИО директора</Text>
          <Form.Item name={'fullNameDirector'}>
            <Input type={'text'}
                   className={s.field}
                   onBlur={(e) => onBlurField(e, 'manager_fullname')}
            />
          </Form.Item>
        </Col>
      </Row>

    </div>
  )
}

export default LegalPerson