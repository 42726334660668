import React from 'react'
import { Col, Row } from 'antd'
import s from './Section.module.scss'

const Section: React.FC<{ ref?: React.ReactNode, children: React.ReactNode }> = ({children}) => {
  return (
    <Row className={s.section}>
      <Col span={24}>{children}</Col>
    </Row>
  )
}

export default Section