import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ConfigProvider, theme } from 'antd'
import { RouteNames } from './configs/routes/routesNames'
import { basicTheme } from './configs/themes/basic'
import { Auth, setCredentials } from './modules/Authorization'
import { useAppDispatch } from './hooks/redux'
import PrivateRouter from './components/PrivateRouter/PrivateRouter'
import LayoutCustom from './components/LayoutCustom/LayoutCustom'
import OrdersPage from './pages/Orders/OrdersPage'
import NotFound from './components/NotFound/NotFound'
import { RoutesProps } from './configs/routes/router.types'
import MaterialsPage from './pages/Materials/MaterialsPage'
import 'dayjs/locale/ru'
import locale from 'antd/locale/ru_RU'
import './styles/index.scss'

export const routes: RoutesProps[] = [
  {
    path: RouteNames.ORDERS,
    Component: OrdersPage,
  },
  {
    path: RouteNames.NOT_FOUND,
    Component: NotFound,
  },
]

const App = () => {
  const tokensLocal = JSON.parse(localStorage.getItem('tokensLL') || '{}')
  const tokensSession = JSON.parse(sessionStorage.getItem('tokensLL') || '{}')
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const {token} = theme.useToken()

  useEffect(() => {
    if (tokensLocal || tokensSession) {
      const tokens = tokensLocal.token ? tokensLocal : tokensSession
      dispatch(setCredentials(tokens))
      navigate(location.pathname)
    } else {
      navigate(RouteNames.AUTHORIZATION)
    }
  }, [])

  return (
    <ConfigProvider theme={basicTheme} locale={locale}>
      <Routes>
        <Route index path="/sign-in" element={<Auth/>}/>

        <Route element={<PrivateRouter/>}>
          <Route element={<LayoutCustom/>}>
            {
              routes.map(({path, Component}, index) => (
                <Route key={index} path={path} element={<Component/>}/>
              ))
            }
            <Route path={RouteNames.MATERIALS} element={<MaterialsPage/>}/>
            <Route path={'/'} element={<Navigate to={RouteNames.ORDERS} replace />}/>
            <Route path={'*'} element={<NotFound/>}/>
          </Route>
        </Route>
      </Routes>
    </ConfigProvider>
  )
}

export default App
