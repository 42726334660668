import { createApi } from '@reduxjs/toolkit/query/react'
import { apiSlice, baseQueryWithReauth, IResponseWithIRI } from './api'
import { IMaterialItem } from '../models/IMaterial'

export const materialOrderApi = apiSlice.injectEndpoints({
  // reducerPath: 'materialOrder',
  // baseQuery: baseQueryWithReauth,
  // tagTypes: ['Materials'],
  // refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    getMaterials: build.query<IMaterialItem[], { page: number, status?: string | string[] }>({
      query: ({page, status}) => ({
        url: '/material-orders',
        params: {
          page,
          status
        }
      }),
      transformResponse: (response: IResponseWithIRI<any>): IMaterialItem[] => {
        return response['hydra:member'].map(item => ({
          hydraMemberID: item['@id'],
          id: item.id,
          createdAt: item.created_at,
          updatedAt: item.updated_at,
          quantity: item.quantity,
          measurement: item.measurement,
          status: item.status,
          note: item.note || '',
          productionDateFrom: item.production_date_from,
          productionDateTo: item.production_date_to,
          product: item.product,
          productName: item.product_name,
          deliveryDateFrom: item.delivery_date_from,
          deliveryDateTo: item.delivery_date_to,
          material: {
            material: item.material.material,
            name: item.material.name,
            price: item.material.price,
            provider: item.material.provider,
          },
          productOrder: {
            productOrderIRI: item.product_order['@id'],
            id: item.product_order.id,
            code: item.product_order.code,
            quantity: item.product_order.quantity
          },
          productOrderItem: {
            productOrderIRI: item.product_order_item['@id'],
            id: item.product_order_item.id,
            quantity: item.product_order_item.quantity
          }
        }))
      },
      providesTags: ['Materials']
    }),
    changeMaterial: build.mutation<IMaterialItem, { id: number, data: any }>({
      query: ({id, data}) => ({
        url: `/material-orders/${id}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: () => ['Materials']
    })
  })
})

export const {useGetMaterialsQuery, useChangeMaterialMutation} = materialOrderApi