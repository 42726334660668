import React from 'react'
import s from './NotFound.module.scss'

const NotFound = () => {
  return (
    <section className={s.section}>
      <h1>
        Страница не найдена
      </h1>
    </section>
  )
}

export default NotFound