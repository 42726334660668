import { createSlice } from '@reduxjs/toolkit'
import { IOrder, IOrderTable } from '../../../models/IOrder'
import { RootState } from '../../store'
import { IDocument } from '../../../models/IDocument'

interface OrderState {
  orders: IOrderTable[]
  orderToEdit: IOrder
  hasRightsForEdit: boolean
  isOrderEdit: boolean
  isShowFormCreateOrder: boolean
  filters: {
    code: string
    dateTo: string
    dateFrom: string
    status: string
    company: string
  }
}

const initialState: OrderState = {
  orders: [],
  isOrderEdit: false,
  hasRightsForEdit: false,
  orderToEdit: {} as IOrder,
  isShowFormCreateOrder: false,
  filters: {
    code: '',
    dateTo: '',
    dateFrom: '',
    status: '',
    company: ''
  }
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFormOrderCreate: (state, action) => {
      state.isShowFormCreateOrder = action.payload
    },
    setOrderToEdit: (state, action) => {
      state.isOrderEdit = action.payload.edit
      state.hasRightsForEdit = action.payload.hasRightsForEdit
      state.orderToEdit = action.payload.orderToEdit
    },
    setFilters: (state, action) => {
      state.filters = {...state.filters, ...action.payload}
    },
  }
})

export const {setFormOrderCreate, setOrderToEdit, setFilters} = orderSlice.actions
export default orderSlice.reducer
export const getOrderToEdit = (state: RootState) => state.orderSlice?.orderToEdit
export const getOrderToEditDoc = (state: RootState) => state.orderSlice?.orderToEdit?.documents
export const getHasRights = (state: RootState) => state.orderSlice.hasRightsForEdit
export const getIsOrderEdit = (state: RootState) => state.orderSlice.isOrderEdit
export const getFilters = (state: RootState) => state.orderSlice.filters
