import { createSlice } from '@reduxjs/toolkit'
import { IUser } from '../../../models/IUser'
import { RootState } from '../../store'

interface UserState {

  user: IUser
  token: string
  isLoading: boolean
  error: string
}

const initialState: UserState = {
  user: {} as IUser,
  token: '',
  isLoading: false,
  error: '',

}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.user = action.payload
    },
  }
})

export const {setUserData} = userSlice.actions
export default userSlice.reducer

export const selectUser = (state: RootState) => state.userSlice?.user
export const selectWorkpoint = (state: RootState) => state.userSlice?.user?.workpoint
