import React, { FC, useEffect, useState } from 'react'
import { PlusCircleFilled } from '@ant-design/icons'
import { Button, Col, Collapse, Form, notification, Row, Select, Space } from 'antd'
import HeaderPanel from './components/HeaderPanel/HeaderPanel'
import Good from './components/Good/Good'
import Payment from './components/Payment/Payment'
import DatesOrder from './components/DatesOrder/DatesOrder'
import { LabeledValue } from 'antd/es/select'
import { TypesOrder } from '../../../../configs/orderInfo/typesOrder'
import { useDeleteProductItemsMutation } from '../../../../services/order.api'
import GoodSize from './components/GoodSize/GoodSize'
import Appliances from './components/Appliances/Appliances'
import Note from './components/Note/Note'
import Materials from './components/Materials/Materials'
import s from './Goods.module.scss'

const {Panel} = Collapse

interface IProps {
  orderData: any
  goodsIDs: number[]
  isEditable: boolean
  sendOnBlurGoods: () => void
  prepaymentPercent?: number
}

const Goods: FC<IProps> = ({orderData, goodsIDs, isEditable, sendOnBlurGoods, prepaymentPercent}) => {
  const [orderType, setOrderType] = useState({key: 'retail', value: 'retail', label: 'На заказ розница'})
  const [api, contextHolder] = notification.useNotification({
    placement: 'topRight'
  })
  const form = Form.useFormInstance()
  const [deleteGood, {
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
    error: errorDelete
  }] = useDeleteProductItemsMutation()

  // уведомление удаления товара--->
  useEffect(() => {
    if (isSuccessDelete) {
      api.success({
        message: `Товар успешно удалён`,
        duration: 3
      })
    }
  }, [isSuccessDelete])
  useEffect(() => {
    if (errorDelete && 'status' in errorDelete) {
      api.error({
        message: `Не удалось удалить товар. Статус: ${errorDelete.status}`,
        duration: 3
      })
    }
  }, [isErrorDelete])
  // <--- уведомление удаления товара

  const addGood = (addFn: ({}) => void, fieldsLength: number) => {
    if (fieldsLength < 15) {
      addFn({
          good: [{
            productName: '',
            price: '',
            quantity: '1',
            cost: '',
            discount: '',
            sum: '',
            sumName: 'Итого',
          }]
        }
      )
    }
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['orderType'], option.key)
  }

  return (
    <>
      {contextHolder}

      <Form.List name={'goods'}>
        {(fields, {add, remove}, {errors}) => (
          <>
            <Row className={s.headerGoods}>
              <Col span={10}>
                <Space className={s.addGood} size={8} align={'center'}>
                  Товары
                  <Button shape="circle"
                          className={s.addGoodBtn}
                          icon={<PlusCircleFilled className={s.addBtnIcon}/>}
                          onClick={() => addGood(add, fields.length)}
                  />
                </Space>
              </Col>
              <Col span={8} offset={6}>
                <Space size={8} className={s.typeOrderSelect}>
                  <Form.Item>
                    Тип заказа:
                  </Form.Item>
                  <Form.Item name={[0, 'orderType']}>
                    <Select
                      style={{width: 187}}
                      dropdownMatchSelectWidth={false}
                      options={TypesOrder}
                      onSelect={onSelect}
                      value={orderType?.label}
                      disabled={isEditable}
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>
            <Collapse
              defaultActiveKey={!isEditable ? ['0'] : ''}
              className={s.collapse}
              accordion={!isEditable}
              destroyInactivePanel={isEditable}
            >
              {fields.map((field, index) => (
                <Panel header={
                  <HeaderPanel removeGood={remove}
                               index={index}
                               countGoods={fields.length}
                               goodsIDs={goodsIDs}
                               deleteGood={deleteGood}
                               isSuccessDelete={isSuccessDelete}
                  />}
                       key={field.name}
                       className={s.panel}
                       showArrow={false}
                       forceRender={isEditable}
                >
                  <Good fieldName={field.name}
                        orderType={orderType}
                        sendOnBlurGoods={sendOnBlurGoods}
                  />
                  <GoodSize fieldName={field.name} sendOnBlurGoods={sendOnBlurGoods}/>
                  <Materials fieldName={field.name} sendOnBlurGoods={sendOnBlurGoods}/>
                  <Appliances fieldName={field.name} sendOnBlurGoods={sendOnBlurGoods}/>
                  <Note fieldName={field.name} sendOnBlurGoods={sendOnBlurGoods}/>
                </Panel>
              ))}
            </Collapse>
          </>
        )}
      </Form.List>

      <Payment isEditable={isEditable} prepaymentPercent={prepaymentPercent}/>
      <DatesOrder
        dates={{
          deliveryDateTo: orderData?.deliveryDateTo || '',
          deliveryDateFrom: orderData?.deliveryDateFrom || '',
          productionDateTo: orderData?.productionDateTo || '',
          productionDateFrom: orderData?.productionDateFrom || ''
        }}
        isEditable={isEditable}
      />
    </>
  )
}

export default Goods