import { Button, ConfigProvider, Form, Input, Modal, notification, Row, Space, Tabs, theme } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import Client from '../Client/Client'
import Goods from '../Goods/Goods'
import OrderHeader from '../Goods/components/OrderHeader/OrderHeader'
import { useAppSelector } from '../../../../hooks/redux'
import Documents from '../../../../components/Documents/Documents'
import { OrderStatuses, OrderStatusForm } from '../../../../configs/enums/orderStatuses'
import { IOrder } from '../../../../models/IOrder'
import {
  useChangeOrderMutation,
  useCreateOrderMutation,
  useGetOrderNameMutation,
  useGetProductOrderStatusesQuery,
  useLazyGetOrderQuery
} from '../../../../services/order.api'
import { selectWorkpoint } from '../../../../store/reducers/user/user.slice'
import { useCreateContactMutation, useLazyGetContactQuery } from '../../../../services/contact.api'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { getHasRights, getIsOrderEdit } from '../../../../store/reducers/order/order.slice'
import GenerateDocument from '../../../../components/GenerateDocument/GenerateDocument'
import { useCreateDocumentMutation } from '../../../../services/document.api'
import { materialDataForSend } from '../../configs/dataTypeForSend.type'
import { downloader } from '../../hooks/downloader/downloader'
import s from './FormOrder.module.scss'

const {useToken} = theme

interface IProps {
  name?: string
  isShowForm: boolean
  closeForm: () => void
  statusForEditing: OrderStatusForm
  data?: IOrder
}

interface IOptions {
  optionIRI: string
  productIRI: string
  productName: string
  quantity: number
  price: string
  cost: string
  discount: string
  sum: string
}

const getOptions = (options: IOptions[]) => {
  const result = []
  for (let i = 1; i < options.length; i++) {
    const data: any = {
      // 'product': options[i].productIRI || null,
      'product_name': options[i].productName,
      'quantity': +options[i].quantity,
      'price': `${options[i].price}`,
      'cost': `${options[i].cost}`,
      'discount': `${options[i].discount}` || '0',
      'summ': options[i].sum
    }
    // if (options[i]?.optionIRI) {
    //   data.id = options[i].optionIRI
    // }
    if (options[i].productIRI) {
      data.product = options[i].productIRI
    }
    result.push(data)
  }
  return result
}

const FormOrder: FC<IProps> = React.memo((props) => {
  const {name = 'newOrder', isShowForm = false, closeForm, statusForEditing, data: dataOrder} = props
  const workpoint = useAppSelector(selectWorkpoint)
  const isOrderEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(state => state.orderSlice.orderToEdit)
  const hasRights = useAppSelector(getHasRights)
  const [cancelForm, setCancelForm] = useState(false)
  const [saveForm, setSaveForm] = useState(false)
  const [saveAndGenDoc, setSaveAndGenDoc] = useState(false)
  const [awaitingCreateOrder, setAwaitingCreateOrder] = useState(false)
  const [dataForDocument, setDataForDocument] = useState({company: '', document: ''})
  const [goodsIDs, setGoodsIDs] = useState<number[]>([])
  const [api, contextHolder] = notification.useNotification()
  const isEditable = ['orderChanging'].includes(statusForEditing)
  const {token} = useToken()

  // const [height, setHeight] = useState(0)
  // const refHeader = useRef(null)
  // const refTabs = useRef(null)
  //
  // useEffect(() => {
  //   // @ts-ignore
  //   setHeight(refHeader?.current?.clientHeight)
  // })
  // useEffect(() => {
  //   console.log(refHeader)
  //   console.log(refTabs)
  // }, [refTabs, refHeader])

  const [getOrderName, {data: orderName}] = useGetOrderNameMutation()
  const {data: orderStatuses} = useGetProductOrderStatusesQuery()
  const [createContact] = useCreateContactMutation()
  const [createOder] = useCreateOrderMutation()
  const [changeOrder, {
    data: changedOrder,
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    error
  }] = useChangeOrderMutation()
  const [getContact] = useLazyGetContactQuery()
  const [fetchOrder] = useLazyGetOrderQuery()
  const [createDoc, {
    data: dataDocuments,
    isLoading: isLoadingGenerateDoc,
  }] = useCreateDocumentMutation()

  const [form] = Form.useForm()
  const goods = Form.useWatch(`goods`, form)
  const prepaymentPercent = Form.useWatch('prepaymentPercent', form)
  const cost = Form.useWatch('cost', form)
  const prepayment = Form.useWatch('prepayment', form)
  const finalPayment = Form.useWatch('finalPayment', form)

  const initialValues = {
    goods:
      isOrderEdit
        ? orderToEdit?.productOrderItems?.map((item, index) => {
          return ({
            good: [
              {
                productId: item.id,
                productIRI: item.product?.productIRI,
                productName: item.product?.name || item.productName,
                price: item.price,
                cost: item.cost,
                quantity: item.quantity,
                discount: item.discount,
                sumName: 'Итого',
              },
              ...item.productOrderItemOptions.map(option => ({
                // optionIRI: option.optionIRI,
                productId: option.id,
                productIRI: option.product?.productIRI || '',
                productName: option.product.name || option.productName,
                price: option.price,
                cost: option.cost,
                quantity: option.quantity,
                discount: option.discount
              }))
            ],
            productOrderItemIRI: item.productOrderItemIRI,
            materialId: item.materials[0].id,
            materialAdditionalId: item.materials[1].id,
            name: item.materials[0].name,
            provider: item.materials[0].provider,
            price: Math.round(+item.materials[0].price) || '',
            additionalName: item.materials[1].name,
            additionalProvider: item.materials[1].provider,
            additionalPrice: Math.round(+item.materials[1].price) || '',
            backwall: item.hasBackwall ? 'true' : '',
            productSupport: item.productSupport,
            size: item.productSize ? item.productSize : item.productSizeValue,
            productSize: item.productSizeValue,
            checkboxCustomSize: item.productSize ? 'false' : 'true',
            note: item.note,
            orderType: orderToEdit?.type ? orderToEdit?.type : 'retail'
          })
        })
        : [{
          good: [{
            productIRI: '',
            productName: '',
            price: '',
            cost: '',
            quantity: '1',
            discount: '',
            sumName: 'Итого',
          }],
          orderType: 'retail'
        }],
    status: orderToEdit?.productOrderStatus?.value
      ? OrderStatuses[orderToEdit?.productOrderStatus?.value]
      : '/api/v1/product-order-statuses/1',
    cost: '',
    prepaymentPercent: orderToEdit?.prepaymentPercent || null,
    prepayment: orderToEdit?.prepaymentSum ? Number(orderToEdit?.prepaymentSum).toFixed(0) : null,
    finalPayment: orderToEdit?.afterPaymentSum ? Number(orderToEdit?.afterPaymentSum).toFixed(0) : null,
    paymentType: orderToEdit?.paymentType?.cyrillicValue ? orderToEdit?.paymentType?.cyrillicValue : '',
  }

  useEffect(() => {
    if (cost && prepaymentPercent >= 1) {
      const finalPayment = (Number(cost) - (Number(cost) * prepaymentPercent) / 100).toFixed(0)
      const prepaymentCalc = (Number(cost) - Number(finalPayment)).toFixed(0)
      form.setFieldValue(['prepayment'], prepaymentCalc || '')
      form.setFieldValue(['finalPayment'], finalPayment || '')
    }
  }, [cost, prepayment, prepaymentPercent])
  useEffect(() => {
    if (!form.isFieldsTouched() || !isEditable) return
    if (prepaymentPercent > 0) {
      const finalPayment = (Number(cost) - (Number(cost) * prepaymentPercent) / 100).toFixed(0)
      const prepaymentCalc = (Number(cost) - Number(finalPayment)).toFixed(0)
      form.setFieldValue(['prepayment'], prepaymentCalc || '')
    }
  }, [cost, prepaymentPercent])
  useEffect(() => {
    if (!form.isFieldsTouched() || !isEditable) return
    if (!prepaymentPercent) {
      form.setFieldValue(['prepayment'], null)
    }
  }, [prepaymentPercent])
  useEffect(() => {
    if (cost && (prepaymentPercent === null || prepaymentPercent === 0) && (!prepayment)) {
      form.setFieldValue(['finalPayment'], cost)
    }
    if (!prepaymentPercent && prepayment && cost) {
      form.setFieldValue(['finalPayment'], Number(cost) - Number(prepayment))
    }
    if (!prepaymentPercent && !prepayment && cost) {
      form.setFieldValue(['finalPayment'], cost)
    }
  }, [cost, prepayment])

  useEffect(() => {
    if (!isEditable && workpoint) {
      getOrderName({prefix: workpoint.shortName})
    }
  }, [isShowForm, workpoint])
  // Уведомления редактирования договора -->
  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: `Не удалось изменить данные. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])
  // <-- Уведомления редактирования договора

  // Загрузка договоров -->
  useEffect(() => {
    if (dataDocuments) {
      for (let index = 0; index < dataDocuments.length; index++) {
        const {link, name} = dataDocuments[index]
        downloader(link, name)
      }
    }
  }, [dataDocuments])
  // <-- Загрузка договоров

  useEffect(() => {
    if (isEditable && changedOrder) {
      for (let i = 0; i < changedOrder.productOrderItems.length; i++) {
        form.setFieldValue(['goods', i, 'good', 0, 'productId'], changedOrder.productOrderItems[i].id)

        for (let j = 0; j < changedOrder.productOrderItems[i].productOrderItemOptions.length; j++) {
          form.setFieldValue(
            ['goods', i, 'good', j + 1, 'productId'],
            changedOrder.productOrderItems[i].productOrderItemOptions[j].id
          )
        }
      }
    }
  }, [changedOrder])
  useEffect(() => {
    if (isEditable && changedOrder) {
      const {productOrderItems} = changedOrder
      const res = []

      // for (const productOrderItemsKey in productOrderItems) {
      //   const {productOrderItemOptions} = productOrderItems[productOrderItemsKey]
      //   res.push(productOrderItems[productOrderItemsKey]?.product?.id)
      //
      //   for (const productOrderItemOptionsKey in productOrderItemOptions) {
      //     res.push(productOrderItemOptions[productOrderItemOptionsKey]?.product?.id)
      //   }
      // }

      for (let i = 0; i < changedOrder.productOrderItems.length; i++) {
        res.push(changedOrder.productOrderItems[i].id)
      }

      setGoodsIDs(res)
    } else if (orderToEdit) {
      const res = []
      for (let i = 0; i < orderToEdit?.productOrderItems?.length; i++) {
        res.push(orderToEdit?.productOrderItems?.[i]?.id)
      }

      setGoodsIDs(res)
    }
  }, [orderToEdit, changedOrder])

  const onFinish = async (fieldsValue: any) => {
    setAwaitingCreateOrder(true)
    setSaveAndGenDoc(false)
    const rangeValueProduction = fieldsValue['productionDates']
    const rangeValueDelivery = fieldsValue['deliveryDates']
    const contact = {
      'type': `${fieldsValue.clientType}`,
      'first_name': fieldsValue.contactFirstName || '',
      'second_name': fieldsValue.contactLastName || '',
      'middle_name': fieldsValue.contactMiddleName || '',
      'full_name': fieldsValue.fullName
        ? fieldsValue.fullName
        : `${fieldsValue.contactFirstName} ${fieldsValue.contactLastName} ${fieldsValue.contactMiddleName}`,
      'phone': fieldsValue.phone,
      'email': fieldsValue.email || '',
      'additional_contacts': fieldsValue.additionalContact || '',
      'address': fieldsValue.deliveryAddress || '',
      'juri_name': fieldsValue.juriName || '',
      'inn': fieldsValue.inn || '',
      'kpp': fieldsValue.kpp || '',
      'ogrn': fieldsValue.ogrn || '',
      'bik': fieldsValue.bik || '',
      'bank_name': fieldsValue.bank || '',
      'corr_account': fieldsValue.corrAccount || '',
      'pay_account': fieldsValue.giroAccount || '',
      'yuri_address': fieldsValue.juriAddress || '',
      'manager_fullname': fieldsValue.fullNameDirector || '',
    }
    const data: any = {
      'code': `${workpoint['shortName']} ${orderName?.counter}`,
      'code_prefix': workpoint['shortName'],
      'code_number': orderName?.counter,
      'type': fieldsValue.orderType,
      'product_order_status': '/api/v1/product-order-statuses/1',
      'price': `${fieldsValue.cost}` || '',
      'payment_type': fieldsValue.paymentType || '',
      'prepayment_percent': +fieldsValue.prepaymentPercent || 0,
      'prepayment_summ': `${fieldsValue.prepayment}`,
      'afterpayment_summ': `${fieldsValue.finalPayment}`,
      'production_date_from': rangeValueProduction[0].format('YYYY-MM-DD'),
      'production_date_to': rangeValueProduction[1].format('YYYY-MM-DD'),
      'delivery_date_from': rangeValueDelivery[0].format('YYYY-MM-DD'),
      'delivery_date_to': rangeValueDelivery[1].format('YYYY-MM-DD'),
      'workpoint': workpoint['@id'],
      'note': '',
      'product_order_items': [
        ...fieldsValue?.goods?.map((item: any) => ({
          'product': item?.good[0].productIRI || null,
          'product_name': item.good[0].productName,
          'product_support': item.productSupport || null,
          'price': `${item.good[0].price}`,
          'quantity': +item.good[0].quantity,
          'cost': `${item.good[0].cost}`,
          'discount': `${item.good[0].discount ? item.good[0].discount : 0}`,
          'summ': item.good[0].sum,
          'product_size': item.productSizeIRI || null,
          'product_size_value': item.productSize,
          'has_backwall': Boolean(item.backwall),
          'note': item.note || '',
          'product_order_item_options': getOptions(item.good),
          'materials': [
            {
              'material': 'Основная ткань',
              'name': item.name,
              'provider': item.provider,
              'price': `${item.price}`
            },
            {
              'material': 'Дополнительная ткань',
              'name': item.additionalName || '',
              'provider': item.additionalProvider || '',
              'price': `${item.additionalPrice || 0}`
            }
          ],
        }))
      ],
    }
    try {
      const chosenContact = form.getFieldValue(['contactIRI'])
      console.log(chosenContact)
      let createContactData, fetchContactData

      if (!chosenContact) {
        createContactData = await createContact(contact)
        // @ts-ignore
        if (createContactData?.error?.status >= 400) {
          // @ts-ignore
          throw new Error(`Ошибка создания контакта. Статус: ${createContactData.error.status}`)
        }

        // @ts-ignore
        fetchContactData = await getContact({id: createContactData?.data?.id})
      }
      console.log(chosenContact)
      console.log(createContactData)
      console.log(fetchContactData)
      // @ts-ignore
      const createOrderData = await createOder({
        ...data,
        'contact': chosenContact ? chosenContact : fetchContactData?.data?.hydraContactID
      })
      console.log(createOrderData)
      if ('data' in createOrderData) {
        console.log(createOrderData?.data?.id)

        const fetchOrderData = await fetchOrder(createOrderData?.data?.id)

        // @ts-ignore
        if (dataForDocument.company && dataForDocument.document && fetchOrderData?.data) {
          await createDoc({
            firm_name: dataForDocument.company,
            collection: dataForDocument.document,
            // @ts-ignore
            product_order: fetchOrderData?.data.orderIRI
          })
        }
      }
      // @ts-ignore
      if (createOrderData?.data) {
        api.success({
          message: `Заказ успешно создан`,
          placement: 'topRight',
          duration: 3
        })
        form.resetFields()
        closeForm()
      } else {
        api.error({
          message: 'Заказ не создан',
          placement: 'topRight',
          duration: 3
        })
      }
    } catch (e: any) {
      console.log(e)
      api.error({
        message: e?.message,
        // @ts-ignore
        description: e?.error?.message,
        placement: 'topRight',
        duration: 3
      })
    } finally {
      setAwaitingCreateOrder(false)
    }
  }

  const handleFormSubmit = () => {
    setSaveForm(false)
    form.submit()
  }

  const generateDocument = (company: string, document: string) => {
    setDataForDocument({company, document})
    form.submit()
  }

  const setFormModal = (value: boolean) => {
    form.resetFields()
    closeForm()
    setCancelForm(value)
  }
  const onSave = () => {
    form.validateFields()
      .then((values) => {
        setSaveForm(true)
        // handleFormSubmit(values)
      })
      .catch((errorInfo) => {
        api.error({
          message: 'Заполните все поля',
          placement: 'topRight',
          duration: 3
        })
      })
  }
  const onSaveAndGenDoc = () => {
    form.submit()
    form.validateFields()
      .then((values) => {
        setSaveAndGenDoc(true)
      })
      .catch((errorInfo) => {
        api.error({
          message: 'Заполните все поля',
          placement: 'topRight',
          duration: 3
        })
      })
  }

  const sendOnBlurGoods = async () => {
    if (!isOrderEdit || !dataOrder?.id) return

    const data = {
      'product_order_items': [
        ...goods?.map((item: any) => {
          const material: materialDataForSend = {
            'material': 'Основная ткань',
            'name': item.name,
            'provider': item.provider,
            'price': `${item.price}`
          }
          const materialAdditional: materialDataForSend = {
            'material': 'Дополнительная ткань',
            'name': item.additionalName || '',
            'provider': item.additionalProvider || '',
            'price': `${0}`
          }
          if (item.materialId) {
            material.id = `/api/v1/materials/${item.materialId}`
          }
          if (item.materialAdditionalId) {
            materialAdditional.id = `/api/v1/materials/${item.materialAdditionalId}`
          }

          const data: any = {
            'id': item?.productOrderItemIRI,
            'product_name': item.good[0].productName,
            'product_support': item.productSupport || null,
            'price': `${item.good[0].price}`,
            'quantity': +item.good[0].quantity,
            'cost': `${item.good[0].cost}`,
            'discount': !!item.good[0]?.discount ? `${item.good[0]?.discount}` : '0',
            'summ': item.good[0].sum,
            'product_size': item.productSizeIRI || null,
            'product_size_value': item.productSize,
            'materials': [material, materialAdditional],
            'has_backwall': Boolean(item.backwall),
            'note': item.note || '',
            'product_order_item_options': getOptions(item.good),
          }

          if (item?.good[0].productIRI) {
            data.product = item?.good[0].productIRI
          }

          return data
        })
      ],
      'price': `${cost}`,
      'prepayment_summ': `${prepayment}`,
      'afterpayment_summ': `${finalPayment}`
    }

    form.validateFields()
      .then((response) => {
        changeOrder({id: dataOrder.id, data})
        return response
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: token.colorTextLabel
      }
    }}>
      {awaitingCreateOrder && <LoaderFullDisplay/>}
      {contextHolder}
      <Form
        name={name}
        form={form}
        onFinish={onFinish}
        layout={'vertical'}
        initialValues={initialValues || {}}
        disabled={isOrderEdit && !hasRights}
        requiredMark={false}
      >

        <Form.Item initialValue={'retail'} name={'orderType'} style={{width: 0, height: 0, margin: 0}}>
          <Input type={'hidden'}/>
        </Form.Item>

        <OrderHeader
          // ref={refHeader}
          name={
            (isEditable)
              ? `${dataOrder?.code || ''}`
              : `${workpoint?.shortName || ''} №${orderName?.counter || ''}`
          }
          orderStatus={
            (isEditable && dataOrder?.productOrderStatus?.productStatusIRI)
              ? dataOrder.productOrderStatus?.productStatusIRI
              : '/api/v1/product-order-statuses/1'
          }
          createDate={isEditable ? (dataOrder?.createdAt || '') : ''}
          isEditable={isEditable}
          orderStatuses={orderStatuses || []}
          orderIRI={dataOrder ? dataOrder.orderIRI : ''}
          closeForm={closeForm}
          responsibleUser={dataOrder?.responsibleUser}
        />

        <Tabs
          // ref={refTabs}
          defaultActiveKey="1"
          items={!isEditable
            ? [
              {
                key: '1',
                label: `Информация о товаре`,
                children: <Goods orderData={dataOrder}
                                 isEditable={isEditable}
                                 sendOnBlurGoods={sendOnBlurGoods}
                                 prepaymentPercent={changedOrder?.prepaymentPercent || orderToEdit?.prepaymentPercent}
                                 goodsIDs={goodsIDs}
                />,
              },
              {
                key: '2',
                label: `Информация по клиенту`,
                children: <Client orderData={dataOrder} orderName={name}/>,
              }
            ]
            : [
              {
                key: '1',
                label: `Информация о товаре`,
                children: <Goods orderData={dataOrder}
                                 isEditable={isEditable}
                                 sendOnBlurGoods={sendOnBlurGoods}
                                 goodsIDs={goodsIDs}
                />,
              },
              {
                key: '2',
                label: `Информация по клиенту`,
                children: <Client orderData={dataOrder}/>,
              },
              {
                key: '3',
                label: `Документы`,
                children: <Documents orderIRI={dataOrder ? dataOrder.orderIRI : ''}/>,
              }
            ]}
          tabBarStyle={{padding: '0 32px 0'}}
        />

        {
          !isOrderEdit &&
          <>
            {
              isShowForm &&
              // <Affix offsetBottom={10}>
              <Row className={s.buttons}>
                <Space size={24}>
                  <Button type={'ghost'} onClick={() => setCancelForm(true)}>Отмена</Button>
                  <Button type={'primary'} onClick={onSave}>Сохранить заказ</Button>
                  <Button type={'primary'} onClick={onSaveAndGenDoc}>Сохранить и создать документы</Button>
                </Space>
              </Row>
              // </Affix>
            }

            <Modal
              open={cancelForm}
              onOk={() => setFormModal(false)}
              onCancel={() => setCancelForm(false)}
              okText="Да"
              cancelText="Нет"
              centered
              closable={false}
            >
              Вы действительно хотите закрыть форму создания заказа?
            </Modal>
            <Modal
              open={saveForm}
              onOk={handleFormSubmit}
              onCancel={() => setSaveForm(false)}
              okText="Да"
              cancelText="Нет"
              centered
              closable={false}
            >
              Вы действительно хотите сохранить заказ?
            </Modal>

            <GenerateDocument open={saveAndGenDoc}
                              generateDocument={generateDocument}
                              isLoadingGenerateDoc={isLoadingGenerateDoc}
                              handleCancel={() => setSaveAndGenDoc(false)}
            />
          </>
        }
      </Form>
    </ConfigProvider>
  )
})

export default FormOrder