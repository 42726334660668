import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'
import { selectCurrentToken } from '../../modules/Authorization/state/auth.slice'

const PrivateRouter = () => {
  const token = useAppSelector(selectCurrentToken)
  const location = useLocation()
  return token ? <Outlet/> : <Navigate to={'/sign-in'} state={{from: location}} replace={true}/>
}

export default PrivateRouter