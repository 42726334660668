import React, { FC, ReactEventHandler, useEffect, useState } from 'react'
import TableOrders from '../../../../components/TableOrders/TableOrders'
import { IOrderTable } from '../../../../models/IOrder'
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux'
import {
  getFilters,
  getIsOrderEdit,
  getOrderToEdit,
  setOrderToEdit
} from '../../../../store/reducers/order/order.slice'
import { FormOrder } from '../../../OrderCreating'
import { useLazyGetOrderQuery, useLazyGetOrdersQuery } from '../../../../services/order.api'
import { OrderStatusForm } from '../../../../configs/enums/orderStatuses'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { Drawer, notification } from 'antd'

const OrdersList: FC = () => {
  const isOrderToEdit = useAppSelector(getIsOrderEdit)
  const orderToEdit = useAppSelector(getOrderToEdit)
  const filters = useAppSelector(getFilters)
  const [loading, setLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()
  const [getOrder] = useLazyGetOrderQuery()
  const [fetchOrders, {data: ordersData, isLoading: isLoadingOrders}] = useLazyGetOrdersQuery()
  const dispatch = useAppDispatch()

  // useEffect(() => {
  //   fetchOrders({perPage: 9})
  // }, [dispatch])
  useEffect(() => {
    const query: any = {}
    if (filters.status) query.status = filters.status
    if (filters.dateFrom) query.dateFrom = filters.dateFrom
    if (filters.dateTo) query.dateTo = filters.dateTo
    if (filters.code) query.code = filters.code
    if (filters.company) query.company = filters.company

    fetchOrders({...query, perPage: 20})
  }, [filters])



  const onRow = (record: IOrderTable) => {
    return {
      onClick: async () => {
        setLoading(true)

        try {
          const res = await getOrder(record.id)
          if (res?.data) {
            dispatch(setOrderToEdit({edit: true, hasRightsForEdit: true, orderToEdit: res.data}))
          } else {
            dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
          }

          if (res.error) {
            api.error({
              // @ts-ignore
              message: `Ошибка получения данных. Статус: ${res.error?.status}`
            })
          }
        } catch (e) {
          console.log(e)
        } finally {
          setLoading(false)
        }
      }
    }
  }

  const closeForm = () => {
    dispatch(setOrderToEdit({edit: false, hasRightsForEdit: false, orderToEdit: null}))
  }

  return (
    <>
      {contextHolder}
      {
        loading && <LoaderFullDisplay/>
      }
      {
        <TableOrders ordersData={ordersData ? ordersData : []}
                     onRow={onRow}
                     loading={isLoadingOrders}
        />
      }

      <Drawer open={isOrderToEdit}
              onClose={closeForm}
              width={985}
              closable={false}
              bodyStyle={{padding: 0}}
              destroyOnClose={true}
      >
        <FormOrder
          name={'orderChanging'}
          isShowForm={isOrderToEdit && !loading}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.EDIT_ORDER}
          data={orderToEdit}
        />
      </Drawer>
    </>
  )
}

export default OrdersList