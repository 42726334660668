import React, { FC } from 'react'
import OrderManagement from '../../../../components/OrderManagement/OrderManagement'
import { useAppDispatch } from '../../../../hooks/redux'
import { setFormOrderCreate } from '../../../../store/reducers/order/order.slice'

interface IProps {
  setIsShow: (value: boolean) => void
}

const ControlPanel: FC<IProps> = ({setIsShow}) => {
  // const {isShowFormCreateOrder} = useAppSelector(state => state.orderSlice)
  const dispatch = useAppDispatch()

  const setIsShowOrderCreate = (value: boolean) => {
    dispatch(setFormOrderCreate(value))
  }
  return (
    <>
      <OrderManagement setIsShow={setIsShowOrderCreate}/>
    </>
  )
}

export default ControlPanel