export enum DocumentTypes {
  phisycal_first = 'Договор физ. лица',
  juridical_first = 'Договор юр. лица ',
  juridical_last = 'Пакет закрывающих документов, для юр.лиц',
  act = 'Акт',
  cheque = 'Чек на доставку',
  invoice = 'Накладная',
  plate = 'Бирка',
  account = 'Счет на оплату',

}