import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ITokens } from '../../../models/ITokens'
import { RootState } from '../../../store/store'

interface AuthState {
  token: string
  refreshToken: string
  isLoading: boolean
  error: string
  isAuth: boolean
}

const initialState: AuthState = {
  token: '',
  refreshToken: '',
  isLoading: false,
  error: '',
  isAuth: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<ITokens>) => {
      const isRemember = localStorage.getItem('rememberLL')
      const {token, refresh_token} = action.payload

      if (isRemember === 'true') {
        localStorage.setItem('tokensLL', JSON.stringify({
          token: action.payload.token,
          refresh_token: action.payload.refresh_token
        }))
      } else if (isRemember === 'false') {
        sessionStorage.setItem('tokensLL', JSON.stringify({
          token: action.payload.token,
          refresh_token: action.payload.refresh_token
        }))
      }
      state.token = token
      state.refreshToken = refresh_token
      state.isAuth = true
    },
    logout: (state) => {
      localStorage.clear()
      sessionStorage.clear()
      state.isAuth = false
      state.token = ''
      state.refreshToken = ''
    }
  }
})

export const {setCredentials, logout} = authSlice.actions
export default authSlice.reducer
export const selectCurrentToken = (state: RootState) => state.authSlice.token















