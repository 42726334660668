import React from 'react'
import { Spin } from 'antd'
import s from './LoaderFullDisplay.module.scss'

const LoaderFullDisplay = () => {
  return (
    <div className={s.background}>
      <Spin size="large"/>
    </div>
  )
}

export default LoaderFullDisplay