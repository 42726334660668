import { ITokens } from '../../../models/ITokens'
import { apiSlice } from '../../../services/api'

const authApi = apiSlice.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    login: build.mutation<ITokens, { email: string, password: string }>({
      query: (body) => ({
        url: '/token',
        method: 'POST',
        body: body,
      }),
    }),
    refresh: build.mutation<ITokens, string>({
      query: (refreshToken) => ({
        url: '/refresh',
        method: 'POST',
        body: {refresh_token: refreshToken},
      }),
    })
  })
})

export const {useLoginMutation} = authApi

