import React, { useRef, useState } from 'react'
import { Layout } from 'antd'
import SideMenu from './compnents/SideMenu/SideMenu'
import s from './LeftMenu.module.scss'
import useMouseMove from './hooks/useMouseMove'

const {Sider} = Layout

const LeftMenu = () => {
  const [isSiderActive, setIsSiderActive] = useState<boolean>(true)
  const ref = useRef<HTMLDivElement>(null)

  useMouseMove(ref,
    () => setIsSiderActive(false),
    () => setIsSiderActive(true)
  )

  return (
    <Sider collapsed={isSiderActive}
           className={s.siderMenu}
           style={{
             overflow: 'auto',
             height: '100vh',
             position: 'fixed',
             left: 0,
             top: 0,
             bottom: 0,
             zIndex: 600
           }}
           ref={ref}
    >
      <SideMenu/>
    </Sider>
  )
}

export default LeftMenu