import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from './api'
import { IProductSupports } from '../models/IProductSupports'

export const productSupportsApi = createApi({
  reducerPath: 'productSupports',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['ProductSupports'],
  endpoints: (build) => ({
    getProductsSupports: build.query<IProductSupports[], void>({
      query: () => ({
        url: '/product-supports'
      }),
      transformResponse: (response: any) => {
        return response['hydra:member']
      },
    }),
  }),
})

export const {useGetProductsSupportsQuery} = productSupportsApi

