import React, { FC, useCallback, useEffect, useRef } from 'react'
import { InfiniteOrdersList } from '../../modules/InfiniteOrdersList'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setFormOrderCreate } from '../../store/reducers/order/order.slice'
import { Drawer } from 'antd'
import { FormOrder } from '../../modules/OrderCreating'
import { OrderStatusForm } from '../../configs/enums/orderStatuses'

const OrdersPage: FC = () => {
  const isShowFormCreateOrder = useAppSelector(state => state.orderSlice.isShowFormCreateOrder)
  const dispatch = useAppDispatch()

  const closeForm = useCallback(() => {
    dispatch(setFormOrderCreate(false))
  }, [])

  return (
    <>
      <InfiniteOrdersList/>
      <Drawer open={isShowFormCreateOrder} width={985} onClose={closeForm} closable={false} bodyStyle={{padding: 0}}>
        <FormOrder
          name={'newOrder'}
          isShowForm={isShowFormCreateOrder}
          closeForm={closeForm}
          statusForEditing={OrderStatusForm.NEW}
        />
      </Drawer>
    </>
  )
}

export default OrdersPage