const addZero = (value: number) => {
  return value < 10 ? '0' + value : value
}

export const transformDate = (date: string | undefined, hasTime: boolean = false): string => {
  if (!date) {
    return ''
  }
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = newDate.getMonth()
  const day = newDate.getDate()
  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()

  return hasTime
    ? `${addZero(day)}.${addZero(month + 1)}.${year} / ${addZero(hours)}:${addZero(minutes)}`
    : `${addZero(day)}.${addZero(month + 1)}.${year}`

}