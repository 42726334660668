import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { LabeledValue } from 'antd/es/select'
import { Col, ConfigProvider, Form, Input, InputNumber, notification, Row, Typography } from 'antd'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { getOrderToEdit } from '../../../../../../store/reducers/order/order.slice'
import { useGetPaymentTypesQuery } from '../../../../../../services/paymentTypes.api'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import s from './Payment.module.scss'

const {Text} = Typography

interface IProps {
  isEditable: boolean
  prepaymentPercent?: number
}

const Payment: FC<IProps> = ({isEditable, prepaymentPercent: prepaymentPercentFromServer}) => {
  const order = useSelector(getOrderToEdit)
  const [api, contextHolder] = notification.useNotification()
  const {data} = useGetPaymentTypesQuery()
  const [changeOrder, {
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    error
  }] = useChangeOrderMutation()
  const form = Form.useFormInstance()
  const goods = Form.useWatch('goods', form)
  const prepaymentPercent = Form.useWatch('prepaymentPercent', form)
  const prepayment = Form.useWatch('prepayment', form)
  const finalPayment = Form.useWatch('finalPayment', form)

  // Уведомления при редактировании заказа -->
  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: `Не удалось изменить данные. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])
  // <-- Уведомления при редактировании заказа
  useEffect(() => {
    if (goods && goods.length) {
      const sum = goods?.reduce((acc: number, item: any) => {
        return acc + Number(item?.good?.[0]?.summative || 0)
      }, 0)

      form.setFieldValue(['cost'], sum || '')
    }
  }, [goods])

  const onBlurFields = () => {
    if (isEditable) {
      changeOrder({
        id: order.id,
        data: {
          'prepayment_percent': +(prepaymentPercent) || 0,
          'prepayment_summ': prepayment,
          'afterpayment_summ': `${finalPayment}`
        }
      })
    }
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['paymentTypeIRI'], option.key)

    if (isEditable) {
      changeOrder({
        id: order.id,
        data: {'payment_type': option.key}
      })
    }
  }

  const onChangePrepayment = () => {
    form.setFieldValue('prepaymentPercent', null)
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: '#8A94A2',
        controlHeight: 44,
      }
    }}>
      {contextHolder}
      <div className={s.payment}>
        <Row>
          <Text className={s.title}>Сумма и оплата</Text>
        </Row>

        <Row gutter={[10, 10]}>
          <Col span={5}>
            <Form.Item name={'cost'}
                       label={'Стоимость заказа'}
                       labelAlign={'left'}
            >
              <Input type={'text'} size={'large'} maxLength={10} className={`${s.fieldInput} ${s.sum}`} disabled/>
            </Form.Item>
          </Col>
          <Col flex={'220px'}>
            <Form.Item name={['paymentType']}
                       rules={[{required: true, message: 'Обязательное поле'}]}
                       label={<>Форма оплаты<Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                       labelAlign={'left'}
            >
              <SelectCustom
                allowClear
                placeholder={'Выберите форму'}
                options={data ? data?.map(item => ({
                  key: item?.paymentTypeIRI,
                  value: item?.paymentTypeIRI,
                  label: item?.cyrillicValue
                })) : []}
                onSelect={onSelect}
                heightOptions={44}
              />
            </Form.Item>
          </Col>
          <Col flex={'110px'}>
            <Form.Item name={'prepaymentPercent'}
                       label={'Предоплата %'}
                       labelAlign={'left'}
            >
              <InputNumber min={0} max={100}
                           maxLength={3}
                // defaultValue={50}
                           size={'middle'}
                           onBlur={onBlurFields}
                           className={s.fieldInput}
                           value={prepaymentPercentFromServer || 3}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={'prepayment'}
                       label={<>Предоплата <Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                       labelAlign={'left'}
                       rules={[{required: true, message: 'Обязательное поле'}]}
            >
              <Input type={'text'}
                     size={'large'}
                     className={s.fieldInput}
                     onChange={onChangePrepayment}
                     onBlur={onBlurFields}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name={'finalPayment'}
                       label={'Доплата'}
                       labelAlign={'left'}
            >
              <Input type={'text'} size={'large'} className={s.fieldInput} disabled/>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

export default Payment