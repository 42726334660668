import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'
import { useGetPagesQuery } from '../../../../services/pages.api'
import { FormOutlined } from '@ant-design/icons'
import s from './sideMenu.module.scss'
import { baseUrlV1, baseUrl } from '../../../../configs/api/api.config'
interface IMenuAnt {
  id: number
  key: string
  icon: any
  label: React.ReactElement
  className: string
}

const SideMenu = () => {
  const [menu, setMenu] = useState<IMenuAnt[]>([])
  const location = useLocation()
  const {data, isSuccess} = useGetPagesQuery()

  useEffect(() => {
    if (isSuccess && data?.length) {
      const sortMenu = data.map((item, index) => ({
        id: item.id,
        key: item.url,
        icon: <img src={baseUrl + item.icon}/>,
        label: <NavLink to={item.url}>{item.title}</NavLink>,
        className: 'menu-item'
      }))
      setMenu(sortMenu.sort((a, b) => a.id - b.id))
    }
  }, [data])

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname]}
      items={menu}
      className={s.menuItem}
    />
  )
}

export default SideMenu