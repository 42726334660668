import React, { lazy, Suspense } from 'react'
import Section from '../../components/Section/Section'

const Materials = lazy(() => {
  return import('../../modules/MaterialsOrder')
    .then(({Materials}) => ({default: Materials}))
})

const MaterialsPage = () => {
  return (
    <Section>
      <Suspense fallback={<div>Загрузка....</div>}>
        <Materials/>
      </Suspense>
    </Section>
  )
}

export default MaterialsPage