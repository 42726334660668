import { IPaymentType } from '../models/IOrder'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from './api'

export const paymentTypesApi = createApi({
  reducerPath: 'paymentTypes',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['PaymentTypes'],
  endpoints: (build) => ({
    getPaymentTypes: build.query<IPaymentType[], void>({
      query: () => ({
        url: '/payment-types',
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((type: any) => ({
          paymentTypeIRI: type['@id'],
          id: type.id,
          name: type.name,
          cyrillicValue: type.cyrillic_value
        }))
      },
    })
  }),
})

export const {useGetPaymentTypesQuery} = paymentTypesApi

