import React, { ChangeEventHandler, FC, useState } from 'react'
import { Col, ConfigProvider, Form, Input, InputNumber, Row } from 'antd'
import s from './Materials.module.scss'

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
}

const Materials: FC<IProps> = ({fieldName, sendOnBlurGoods}) => {
const [value, setValue] = useState<number | undefined>(undefined)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value)
    // const nums = displayValue && displayValue.replace(/[^0-9]/g,"")
    // setValue(displayValue ? +displayValue : null)
  }

  return (
    <ConfigProvider theme={{
      token: {
        controlHeight: 44,
      },
    }}>
      <div className={s.materials}>
        <Row className={s.headerMaterials} gutter={[12, 12]}>
          <Col span={6}>Материал</Col>
          <Col span={6}>Наименование</Col>
          <Col span={6}>Поставщик</Col>
          <Col span={6}>Цена</Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'material']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Основная ткань'}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'name']} rules={[{required: true, message: 'Обязательное поле'}]}>
              <Input type={'text'}
                     size={'large'}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'provider']} rules={[{required: true, message: 'Обязательное поле'}]}>
              <Input type={'text'}
                     size={'large'}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}/>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'price']} rules={[{required: true, message: 'Обязательное поле'}]}>
              <Input type={'number'}
                     size={'large'}
                     maxLength={10}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalMaterial']}>
              <Input type={'text'}
                     className={s.materialsName}
                     disabled={true}
                     defaultValue={'Дополнительная ткань'}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalName']}>
              <Input type={'text'}
                     size={'large'}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalProvider']}>
              <Input type={'text'}
                     size={'large'}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={[fieldName, 'additionalPrice']}>
              <Input type={'number'}
                     size={'large'}
                     maxLength={10}
                     className={s.fieldInput}
                     onBlur={sendOnBlurGoods}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </ConfigProvider>
  )
}

export default Materials