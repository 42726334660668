import React, { FC, useState } from 'react'
import { Button, Checkbox, Form, Input, Row, Space } from 'antd'
import { useAppDispatch } from '../../../../hooks/redux'
import { setCredentials } from '../../state/auth.slice'
import { useLazyMeQuery } from '../../../../services/user.api'
import { useNavigate } from 'react-router-dom'
import { RouteNames } from '../../../../configs/routes/routesNames'
import LoaderFullDisplay from '../../../../components/LoaderFullDisplay/LoaderFullDisplay'
import { setUserData } from '../../../../store/reducers/user/user.slice'
import { useLoginMutation } from '../../services/auth.api'
import s from './FormSignIn.module.scss'

const FormSignIn: FC = () => {
  const [login, {isLoading}] = useLoginMutation()
  const [me, {isLoading: isLoadingMe}] = useLazyMeQuery()
  const [errors, setErrors] = useState('')
  const [validateStatus, setValidateStatus] = useState<'' | 'error' | 'validating' | 'warning' | 'success' | undefined>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const onFinish = async (values: any) => {
    localStorage.setItem('rememberLL', values.remember)

    try {
      const tokenData = await login({email: values.email, password: values.password}).unwrap()
      dispatch(setCredentials(tokenData))
      const userData = await me()
      dispatch(setUserData(userData.data))
      navigate(RouteNames.ORDERS)
    } catch (err: any) {
      if (err.status === 401) {
        setErrors('Неправильный email или пароль!')
        setValidateStatus('error')
      } else if (err.status >= 500 && err.status <= 599) {
        setErrors('Ошибка сервера')
        setValidateStatus('error')
      } else {
        setErrors('Не известная ошибка')
        setValidateStatus('')
      }
    }
  }

  return (
    <Form
      name="auth"
      className={s.loginForm}
      initialValues={{remember: true}}
      onFinish={onFinish}
    >
      {
        isLoading && <LoaderFullDisplay/>
      }
      <h3 className={s.title}>Вход в систему</h3>

      <Form.Item
        name="email"
        rules={[{type: 'email', message: 'Email должен быть в формате email@email.com',},
          {required: true, message: 'Поле обязательное!'}]}
        validateStatus={validateStatus}
      >
        <Input placeholder="E-mail"
               className={s.field}
               disabled={isLoadingMe}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{required: true, message: 'Поле обязательное!'}]}
        validateStatus={validateStatus}
      >
        <Input
          type="password"
          placeholder="Пароль"
          className={s.field}
          disabled={isLoadingMe}
        />
      </Form.Item>


      <Row className={s.btns}>
        <Space size={30}>
          <Button type="primary"
                  htmlType="submit"
                  className={s.loginButton}
                  disabled={isLoadingMe}
          >
            Войти
          </Button>

          <Form.Item name="remember" noStyle valuePropName={'checked'}>
            <Checkbox>Запомнить меня</Checkbox>
          </Form.Item>
        </Space>
        {
          errors &&
          <Form.ErrorList
            className={s.error}
            help={errors}
          />
        }
      </Row>
    </Form>
  )
}

export default FormSignIn