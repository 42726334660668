import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { AutoComplete, Col, Form, Input, Row, Typography } from 'antd'
import s from './IndividualPerson.module.scss'
import { MailFilled } from '@ant-design/icons'
import { IContact } from '../../../../../../models/IContact'
import { MaskedInput } from 'antd-mask-input'

const {Text} = Typography

interface IProps {
  dataContact: IContact[]
  onBlurField: (e: React.FocusEvent<HTMLInputElement>, fieldName: string) => void
  onSearchContact: (e: ChangeEvent<HTMLInputElement>) => void
  changeContactDataForOrder: (contactIRI: string) => void
}

const IndividualPerson: FC<IProps> = ({
                                        dataContact,
                                        onBlurField,
                                        onSearchContact,
                                        changeContactDataForOrder
                                      }) => {
  const [contact, setContact] = useState({} as IContact)
  const form = Form.useFormInstance()

  useEffect(() => {
    if (contact.phone) {
      form.setFieldValue(['email'], contact.email)
      form.setFieldValue(['contacts'], contact.additionalContacts)
      form.setFieldValue(['addressDelivery'], contact.address)
      form.setFieldValue(['contactFirstName'], contact.firstName)
      form.setFieldValue(['contactLastName'], contact.lastName)
      form.setFieldValue(['contactMiddleName'], contact.middleName)
    }
  }, [contact.phone])

  const onSelectContact = (value: any, option: any) => {
    console.log(value, option)
    setContact(option)
    changeContactDataForOrder(option.hydraContactID)
  }

  return (
    <>
      <Row gutter={[16, 24]}>
        <Col span={8}>
          <Text className={s.fieldName}>Фамилия</Text>
          <Form.Item name={'contactLastName'} initialValue={''}>
            <Input className={s.field} onBlur={(e) => onBlurField(e, 'second_name')}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Имя<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'contactFirstName'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <Input type={'text'} className={s.field} onBlur={(e) => onBlurField(e, 'first_name')}/>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Отчество</Text>
          <Form.Item name={'contactMiddleName'}>
            <Input type={'text'} className={s.field} onBlur={(e) => onBlurField(e, 'middle_name')}/>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={8}>
          <Text className={s.fieldName}>Телефон<Text type={'danger'}>*</Text></Text>
          <Form.Item name={'phone'}
                     rules={[{required: true, message: 'Обязательное поле'}]}
          >
            <AutoComplete
              className={s.field}
              placeholder={'+_ (___) ___ __ __'}
              options={dataContact && dataContact.map((item) => ({
                ...item,
                key: item.id,
                value: item.phone,
                label: `${item.firstName || item.fullName}: ${item.phone}`
              }))}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlurField(e, 'phone')}
              onChange={(e) => onSearchContact(e)}
              children={<Input style={{height: '44px'}}/>}
              onSelect={onSelectContact}
              backfill={true}
              // value={contact.phone}
              // children={<MaskedInput
              //   style={{height: '44px'}}
              //   mask={'+7 (000) 000 00 00'}
              //   onChange={(e) => onSearchContact(e)}
              // />}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Почта</Text>
          <Form.Item name={'email'}>
            <Input type={'text'}
                   className={s.field}
                   placeholder={'Введите почту'}
                   prefix={<MailFilled className={s.icon}/>}
                   onBlur={(e) => onBlurField(e, 'email')}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Text className={s.fieldName}>Доп. контакты</Text>
          <Form.Item name={'additionalContact'}>
            <Input type={'text'} className={s.field}
                   onBlur={(e) => onBlurField(e, 'additional_contacts')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Text className={s.fieldName}>Адрес доставки</Text>
          <Form.Item name={'deliveryAddress'}>
            <Input type={'text'} className={s.field}
                   onBlur={(e) => onBlurField(e, 'address')}
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

export default IndividualPerson