import React, { useEffect, useRef } from 'react'
import ControlPanel from '../ControlPanel/ControlPanel'
import OrdersList from '../OrdersList/OrdersList'
import { useAppDispatch } from '../../../../hooks/redux'
import { setFormOrderCreate } from '../../../../store/reducers/order/order.slice'
import Section from '../../../../components/Section/Section'

export const InfiniteOrdersList = () => {
  const dispatch = useAppDispatch()
  const setIsShow = (value: boolean) => {
    dispatch(setFormOrderCreate(value))
  }

  const ref = useRef<any>()
  useEffect(() => {
    // console.log('scrolls')
    if (!ref) return

    ref?.current?.addEventListener('scroll', scrollHandler)
    return () => {
      // console.log('unmount')
      ref?.current?.removeEventListener('scroll', scrollHandler)
    }
  }, [dispatch])

  const scrollHandler = (e: any) => {
    // console.log(e.target.offsetHeight)
  }

  return (
    // <div ref={ref} style={{display: 'flex', height: '100vh', overflowY: 'scroll'}}>
      <Section>
        <ControlPanel setIsShow={setIsShow}/>
        <OrdersList/>
      </Section>
    // </div>
  )
}
