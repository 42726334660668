export enum OrderStatuses {
  'new' = 'Новый',
  'waiting_for_payment' = 'Ожидает предоплату',
  'production' = 'В производстве',
  'waiting_for_afterpayment' = 'Ожидает доплату',
  'waiting_for_load' = 'Ожидает отгрузку',
  'loaded' = 'Отгружен',
  'cancel' = 'Отменен',
  'deleted' = 'Удален',
  'ready' = 'Готов'
}

export enum OrderStatusForm {
  NEW = 'new',
  EDIT_ORDER = 'orderChanging'
}