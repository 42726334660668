import React, { FC, useEffect, useState } from 'react'
import { CloseOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, ConfigProvider, DatePicker, Input, Row, Space, theme } from 'antd'

import locale from 'antd/es/date-picker/locale/ru_RU'
import SelectCustom from '../../ui/SelectCustom/SelectCustom'
import { setFilters } from '../../store/reducers/order/order.slice'
import { useAppDispatch } from '../../hooks/redux'
import { useGetProductOrderStatusesQuery } from '../../services/order.api'
import { IOrderStatus } from '../../models/IOrder'
import { optionNameCompany } from '../../configs/documents/optionNameCompany'
import s from './OrderManagement.module.scss'

const {RangePicker} = DatePicker
const {useToken} = theme

interface IProps {
  setIsShow: (value: boolean) => void
  children?: React.ReactNode
}

const OrderManagement: FC<IProps> = ({setIsShow}) => {
  const {data: orderStatuses} = useGetProductOrderStatusesQuery()
  const dispatch = useAppDispatch()
  const {token} = useToken()

  // Изменение фильтров для поиска заказов
  const onBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilters({code: e.target.value}))
  }
  const onPressEnter = (e: any) => {
    dispatch(setFilters({code: e.target.value}))
  }
  const onChangeDate = (values: any, formatString: [string, string]) => {
    dispatch(setFilters({dateFrom: formatString[0], dateTo: formatString[1]}))
  }
  const onSelect = (value: string) => {
    dispatch(setFilters({status: value}))
  }
  const onClearStatus = () => {
    dispatch(setFilters({status: ''}))
  }
  const onClearCompany = () => {
    dispatch(setFilters({company: ''}))
  }

  const onChangeComp = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setFilters({company: e}))
  }

  return (
    <ConfigProvider theme={{token: {controlHeight: 40}}}>
      <h3 className={s.title}>Все заказы</h3>

      <Row gutter={[0, 20]} className={s.orderManagement} justify={'space-between'}>
        <Col span={4} className={s.btnCreate}>
          <Button type={'primary'}
                  size={'middle'}
                  icon={<PlusOutlined/>}
                  onClick={() => setIsShow(true)}
                  style={{height: '40px'}}
          >
            Создать заказ
          </Button>
        </Col>
        <Col flex={'0 0 auto'}>
          <Space size={[20, 20]}>
            <SelectCustom
              className={s.select}
              heightOptions={40}
              widthOptions={190}
              placeholder={'Компания'}
              showArrow={false}
              dropdownMatchSelectWidth={false}
              allowClear
              onClear={onClearCompany}
              clearIcon={<CloseOutlined/>}
              suffixIcon={<FilterOutlined/>}
              options={optionNameCompany}
              onSelect={onChangeComp}
            />
            <Input className={s.field}
                   style={{width: '100px'}}
                   placeholder="Поиск по заказам"
                   onBlur={onBlurInput}
                   onPressEnter={onPressEnter}
            />
            <RangePicker className={s.field}
                         locale={locale}
                         onChange={onChangeDate}
                         format="DD.MM.YYYY"
            />
            <SelectCustom
              style={{paddingLeft: token.controlPaddingHorizontal}}
              widthOptions={200}
              className={s.field}
              heightOptions={40}
              dropdownMatchSelectWidth={false}
              placeholder={'Статус'}
              showArrow={false}
              clearIcon={<CloseOutlined/>}
              allowClear
              options={
                orderStatuses ? orderStatuses?.map((status: IOrderStatus) => ({
                  key: status.orderStatusIRI,
                  value: status.orderStatusIRI,
                  label: status.cyrillicValue
                })) : []
              }
              optionLabelProp="label"
              onSelect={onSelect}
              onClear={onClearStatus}
              suffixIcon={<FilterOutlined/>}
            />
          </Space>
        </Col>
      </Row>
    </ConfigProvider>
  )
}

export default OrderManagement