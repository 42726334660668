import React, { FC, useEffect, useState } from 'react'
import { Checkbox, Col, ConfigProvider, Form, Input, Row, Space } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import SelectCustom from '../../../../../../ui/SelectCustom/SelectCustom'
import { useLazyGetProductSizeQuery } from '../../../../../../services/product.api'
import s from './GoodSize.module.scss'
import { LabeledValue } from 'antd/es/select'

interface IProps {
  fieldName: number
  sendOnBlurGoods: () => void
}

const GoodSize: FC<IProps> = ({fieldName, sendOnBlurGoods}) => {
  const [isCustomSize, setIsCustomSize] = useState(false)
  const [disabledCheckbox, setDisabledCheckbox] = useState(false)
  const [getSizesProduct, {data: dataSizesProduct, isLoading: isLoadingSizesProduct}] = useLazyGetProductSizeQuery()
  const form = Form.useFormInstance()
  const orderType = Form.useWatch(`orderType`, form)
  const productIRI = Form.useWatch(['goods', fieldName, 'good', 0, 'productIRI'], form)

  useEffect(() => {
    getSizesProduct({product: productIRI})
  }, [productIRI])

  useEffect(() => {
    if (orderType === 'personal') {
      // form.setFieldValue(['goods', fieldName, `size`], null)
      form.setFieldValue([fieldName, 'checkboxCustomSize'], true)
      setIsCustomSize(true)
      setDisabledCheckbox(true)
    } else {
      setDisabledCheckbox(false)
    }
  }, [orderType])

  const setFieldCustomSize = (e: CheckboxChangeEvent) => {
    setIsCustomSize(e.target.checked)
    form.setFieldValue(['goods', fieldName, `size`], null)
  }

  const onSelect = (value: string | number | LabeledValue, option: any) => {
    form.setFieldValue(['goods', fieldName, 'productSizeIRI'], option.value)
    form.setFieldValue(['goods', fieldName, 'productSize'], option.label)
  }

  const onChangeCustomSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue(['goods', fieldName, 'productSize'], e.target.value)
  }

  return (
    <ConfigProvider theme={{
      token: {
        colorText: '#8A94A2'
      }
    }}>
      <Space direction={'vertical'} size={12} style={{width: '100%', padding: '13px 16px 16px'}}>
        {/*<Row style={{width: '100%'}}>*/}
        {/*  <Col span={24}>Размер</Col>*/}
        {/*</Row>*/}
        <Row>
          <Col span={15}>
            <Space size={16} align={'start'}>
              <Form.Item
                name={[fieldName, 'size']}
                style={{marginBottom: 0}}
                rules={[
                  {
                    required: true,
                    message: 'Обязательное поле'
                  }
                ]}
                label={'Размер'}
                labelAlign={'left'}
              >
                {
                  !isCustomSize
                    ?
                    <SelectCustom
                      widthOptions={334}
                      heightOptions={44}
                      loading={isLoadingSizesProduct}
                      options={
                        dataSizesProduct?.length
                          ? dataSizesProduct.map(item => ({value: item.productSizeIRI, label: item.value}))
                          // : data?.length
                          //   ? data.map(item => ({value: item.productSizeIRI, label: item.value}))
                          : []
                      }
                      style={{width: '334px', fontSize: '16px'}}
                      placeholder={'Выберите размер'}
                      onSelect={onSelect}
                      onBlur={sendOnBlurGoods}
                    />
                    : <Input
                      type={'text'}
                      className={s.inputCustomSize}
                      placeholder="Введите размер"
                      onChange={onChangeCustomSize}
                      onBlur={sendOnBlurGoods}
                    />
                }
              </Form.Item>
              <Form.Item
                name={[fieldName, 'checkboxCustomSize']}
                className={s.checkboxSize}
                label={' '}
              >
                <Checkbox
                  onChange={setFieldCustomSize}
                  checked={isCustomSize}
                  disabled={disabledCheckbox}
                /> Другой размер
              </Form.Item>
            </Space>
          </Col>
        </Row>
      </Space>
    </ConfigProvider>
  )
}

export default GoodSize