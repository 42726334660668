import React, { FC, useEffect } from 'react'
import { Col, DatePicker, Form, notification, Row, Typography } from 'antd'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useChangeOrderMutation } from '../../../../../../services/order.api'
import { useSelector } from 'react-redux'
import { getOrderToEdit } from '../../../../../../store/reducers/order/order.slice'
import dayjs from 'dayjs'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { configDatePikerRange } from '../../../../configs/fieldRules'
import s from './DatsOrder.module.scss'

dayjs.extend(customParseFormat)

const {RangePicker} = DatePicker
const {Text} = Typography

interface IProps {
  dates: {
    deliveryDateFrom: string
    deliveryDateTo: string
    productionDateFrom: string
    productionDateTo: string
  }
  isEditable: boolean
}

const DatesOrder: FC<IProps> = ({dates, isEditable}) => {
  const order = useSelector(getOrderToEdit)
  const [api, contextHolder] = notification.useNotification()
  const form = Form.useFormInstance()
  const [changeOrder, {
    isSuccess: isSuccessChangeOrder,
    isError: isErrorChangeOrder,
    error
  }] = useChangeOrderMutation()

  useEffect(() => {
    if (isSuccessChangeOrder) {
      api.success({
        message: 'Заказ успешно изменен',
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isSuccessChangeOrder])
  useEffect(() => {
    if (error && 'status' in error) {
      api.error({
        message: `Не удалось изменить данные. Статус: ${error.status}`,
        placement: 'topRight',
        duration: 3
      })
    }
  }, [isErrorChangeOrder])

  useEffect(() => {
    if (dates?.deliveryDateFrom && dates?.deliveryDateTo) {
      form.setFieldValue('productionDates',
        [dayjs(dates?.productionDateFrom, 'YYYY.MM.DD'), dayjs(dates?.productionDateTo, 'YYYY.MM.DD')])
      form.setFieldValue('deliveryDates',
        [dayjs(dates?.deliveryDateFrom, 'YYYY.MM.DD'), dayjs(dates?.deliveryDateTo, 'YYYY.MM.DD')])
    }
  }, [])
  const onCalendarChangeProduction = (dates: any, dateStrings: any) => {
    if (isEditable) {
      const data: any = {
        production_date_from: dateStrings[0]
      }
      if (dateStrings[1]) {
        data['production_date_to'] = dateStrings[1]
      }

      changeOrder({id: order.id, data})
    }
  }
  const onCalendarChangeDelivery = (dates: any, dateStrings: any) => {
    if (isEditable) {
      const data: any = {
        delivery_date_from: dateStrings[0]
      }
      if (dateStrings[1]) {
        data['delivery_date_to'] = dateStrings[1]
      }

      changeOrder({id: order.id, data})
    }
  }

  return (
    <div className={s.datesOrder}>
      {contextHolder}
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item name={'productionDates'}
                     label={<>Дата изготовления<Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                     labelAlign={'left'}
                     {...configDatePikerRange}
          >
            <RangePicker
              allowEmpty={[false, false]}
              placeholder={['', '']}
              onCalendarChange={onCalendarChangeProduction}
              format="DD.MM.YYYY"
              allowClear={!isEditable}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name={'deliveryDates'}
                     label={<>Дата доставки<Text type={'danger'} style={{fontSize: '16px'}}>*</Text></>}
                     labelAlign={'left'}
                     {...configDatePikerRange}
          >
            <RangePicker
              locale={locale}
              allowEmpty={[false, false]}
              placeholder={['', '']}
              onCalendarChange={onCalendarChangeDelivery}
              format="DD.MM.YYYY"
              allowClear={!isEditable}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  )
}

export default DatesOrder


