import { ConfigProvider, Select, SelectProps } from 'antd'
import React, { FC } from 'react'
import s from './SelectCustom.module.scss'

interface IProps {
  widthOptions?: number | string
  heightOptions?: number
  prefixIcon?: React.ReactNode
}

const SelectCustom: FC<SelectProps & IProps> = ({
                                                  widthOptions = 'auto',
                                                  heightOptions,
                                                  prefixIcon,
                                                  ...restProps
                                                }) => {

  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: heightOptions || 40,
        },
      }}
    >
      <div className={s.container} style={{width: widthOptions, height: heightOptions}}>
        {prefixIcon && <div className={s.prefixIcon}>{prefixIcon}</div>}
        <Select {...restProps} style={{width: '100%'}}/>
      </div>
    </ConfigProvider>
  )
}


export default SelectCustom