import { useEffect } from 'react'

const useMouseMove = (
  el: any,
  handlerOpen: () => void,
  handlerClose: () => void
) => {

  useEffect(() => {
    const currEl = el.current

    currEl.addEventListener('mouseover', handlerOpen)
    currEl.addEventListener('mouseout', handlerClose)

    return () => {
      currEl.removeEventListener('mouseover', handlerOpen)
      currEl.removeEventListener('mouseout', handlerClose)
    }
  }, [el, handlerOpen, handlerClose])
}

export default useMouseMove