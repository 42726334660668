import React, { useEffect } from 'react'
import { Avatar, Button, Col, Layout, Row, Space } from 'antd'
import { ExportOutlined } from '@ant-design/icons'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { logout } from '../../modules/Authorization'
import { selectUser, setUserData } from '../../store/reducers/user/user.slice'
import { useLazyMeQuery } from '../../services/user.api'
import s from './HeaderApp.module.scss'
import { getInitialsFromName } from '../../helpers/getInitialsFromName'

const {Header} = Layout

const HeaderApp = () => {
  const [me, {data: dataUser, isSuccess, error}] = useLazyMeQuery()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user?.firstName) {
      me()
    }
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(setUserData(dataUser))
    }
  }, [dataUser])

  const onLogout = () => {
    dispatch(logout())
  }

  return (
    <Header className={s.header}>
      <div className={s.user}>
        <Row gutter={12} align={'middle'} justify="end">
          <Col flex={250}>
            <Space size={12}>
              {
                getInitialsFromName(user?.firstName, user?.lastName, user?.middleName)
              }
              <Avatar style={{backgroundColor: '#F7CC4D', verticalAlign: 'middle'}} size="large" gap={10}>
                {
                  getInitialsFromName(user?.firstName, '', user?.middleName)
                }
              </Avatar>
            </Space>
          </Col>
          <Col flex={50}>
            <Button type={'ghost'} onClick={onLogout} icon={<ExportOutlined/>}/>
          </Col>
        </Row>
      </div>
    </Header>
  )
}

export default HeaderApp