import { IUser } from '../models/IUser'
import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from './api'

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User'],
  endpoints: (build) => ({
    me: build.query<IUser, void>({
      query: () => ({
        url: '/users/me',
      }),
      transformResponse: (response: any): IUser => {
        return ({
          ...response,
          firstName: response.first_name,
          lastName: response.last_name,
          middleName: response.middle_name,
          createdAt: response.created_at,
          updatedAt: response.updated_at,
          userRole: response.user_role,
          workpoint: {
            ...response.workpoint,
            shortName: response.workpoint.short_name
          }
        })
      },
    }),
    getUsers: build.query<IUser[], void>({
      query: () => ({
        url: '/users'
      }),
      transformResponse: (response: any) => {
        console.log(response)

        return response['hydra:member']
      },
    })
  })
})

export const {useLazyMeQuery} = userApi
