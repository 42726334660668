import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from './api'
import { IProduct, IProductPrice, IProductSize } from '../models/IProduct'

export const productApi = createApi({
  reducerPath: 'product',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Product'],
  endpoints: (build) => ({
    getProduct: build.query<IProduct, number>({
      query: (id) => `/products/${id}`,
      transformResponse: (response: any) => {
        return {
          ...response['hydra:member'],
          paymentType: response['hydra:member'].payment_type.name

        }
      },
      providesTags: result => ['Product']
    }),
    getProducts: build.query<IProduct[], { type?: string, name?: string } | null>({
      query: (data) => ({
        url: '/products',
        params: {
          type: data?.type,
          name: data?.name
        }
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((product: any) => ({
          productIRI: product['@id'],
          id: product.id,
          name: product.name
        }))
      },
    }),
    getProductSize: build.query<IProductSize[], { product?: string | string[] | null }>({
      query: ({product}) => ({
        url: `/product-sizes`,
        params: {
          product
        },
      }),
      transformResponse: (response: any) => {
        return response['hydra:member'].map((size: any) => ({
          productSizeIRI: size['@id'],
          id: size.id,
          value: size.value
        }))
      },
    }),
    getProductPrices: build.query<
      IProductPrice[],
      {
        type?: string,
        product: string,
        productSize?: string,
        materialPrice?: string
      }>({
      query: (data) => ({
        url: `/product-prices`,
        params: {
          type: data.type,
          product: data.product,
          product_size: data.productSize,
          material_price_search: data.materialPrice
        },
      }),
      transformResponse: (response: any) => {
        return response['hydra:member']
      },
    }),
  })
})

export const {
  useGetProductsQuery,
  useLazyGetProductsQuery,
  useGetProductSizeQuery,
  useLazyGetProductSizeQuery,
  useLazyGetProductPricesQuery
} = productApi

